/*!

 =========================================================
 * Paper Kit React - v1.0.0 based on Paper Kit - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors - used for select dropdown         */
/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 30px;
  border: 0;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 5px;
  line-height: 8px;
  width: 61px !important;
  height: 26px;
  outline: none;
  z-index: 0;
  margin-right: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 26px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important; }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 12px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out; }
  .bootstrap-switch .bootstrap-switch-handle-on i,
  .bootstrap-switch .bootstrap-switch-handle-off i,
  .bootstrap-switch .bootstrap-switch-label i {
    font-size: 12px;
    line-height: 14px; }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  width: 50% !important;
  background-color: #66615B; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #51cbce; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #51bcda; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #6bd098; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #fbc658;
  color: #fff; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #f5593d; }

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
  background: #66615B; }

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 22px !important;
  height: 22px;
  margin: 2px -11px;
  border-radius: 12px;
  position: relative;
  float: left;
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25); }

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px; }

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto; }

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33; }

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important; }

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -37px !important; }

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px; }

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px -13px -11px; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off {
  background-color: #66615B; }

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-handle-on {
  background-color: #cfcfca; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-danger {
  background-color: #f5593d; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-warning {
  background-color: #fbc658; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-success {
  background-color: #6bd098; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary {
  background-color: #51cbce; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-info {
  background-color: #51bcda; }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-default {
  background-color: #66615B; }

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-danger,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-primary,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-info,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-warning,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-success {
  background-color: #cfcfca; }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */ }

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  border-radius: 0;
  height: 2px;
  background: #c8c8c8; }
  .noUi-origin[style^="left: 0"] .noUi-handle {
    background-color: #fff;
    border: 2px solid #c8c8c8; }
    .noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
      border-width: 1px; }

.noUi-handle {
  position: relative;
  z-index: 1; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

/* Styling;
 */
.noUi-target {
  background: #c8c8c8;
  border-radius: 4px; }

.noUi-connect {
  background: #3FB8AF;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize; }

.noUi-vertical .noUi-draggable {
  cursor: n-resize; }

.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #FFFFFF; }
  .noUi-handle.noUi-active {
    transform: scale3d(1.5, 1.5, 1); }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

.slider {
  background: #c8c8c8; }
  .slider .noUi-connect {
    background-color: #51cbce;
    border-radius: 4px; }
  .slider .noUi-handle {
    border-color: #51cbce; }
  .slider.slider-info .noUi-connect {
    background-color: #51bcda; }
  .slider.slider-info .noUi-handle {
    border-color: #51bcda; }
  .slider.slider-success .noUi-connect {
    background-color: #6bd098; }
  .slider.slider-success .noUi-handle {
    border-color: #6bd098; }
  .slider.slider-warning .noUi-connect {
    background-color: #fbc658; }
  .slider.slider-warning .noUi-handle {
    border-color: #fbc658; }
  .slider.slider-danger .noUi-connect {
    background-color: #f5593d; }
  .slider.slider-danger .noUi-handle {
    border-color: #f5593d; }

/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.7.14
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.bootstrap-datetimepicker-widget {
  list-style: none; }

.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 4px;
  width: 16em; }

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-bottom: 11px solid #F1EAE0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  left: auto;
  top: -12px; }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  left: auto;
  top: -11px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: -20px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
  margin-top: auto;
  margin-bottom: 5px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px; }

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0; }

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
  border-width: 0;
  color: #66615B;
  background-color: transparent; }

.bootstrap-datetimepicker-widget a[data-action="togglePicker"],
.bootstrap-datetimepicker-widget a[data-action="togglePicker"]:hover {
  color: #429cb6 !important; }

.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.85); }

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none; }

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.5em;
  margin: 3px;
  border-radius: 50%; }

.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  height: 38px;
  padding: 0; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM"; }

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker"; }

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today"; }

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 4px; }

.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens"; }

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit; }

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0px !important; }

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0; }

.bootstrap-datetimepicker-widget table td > div, .bootstrap-datetimepicker-widget table th > div {
  text-align: center; }

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px; }

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px; }

.bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table th.prev span, .bootstrap-datetimepicker-widget table th.next span {
  border-radius: 4px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center; }

.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month"; }

.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month"; }

.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  border-bottom: 1px solid #E3E3E3;
  font-size: 12px;
  text-transform: uppercase;
  color: #9A9A9A;
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px; }

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer; }

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span, .bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background: #E3E3E3; }

.bootstrap-datetimepicker-widget table td > div {
  border-radius: 4px;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center; }

.bootstrap-datetimepicker-widget table td.cw > div {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #cfcfca; }

.bootstrap-datetimepicker-widget table td.day > div {
  height: 30px;
  line-height: 31px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: -1;
  position: relative; }

.table-condensed .day:hover div {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }

.bootstrap-datetimepicker-widget table td.minute > div, .bootstrap-datetimepicker-widget table td.hour > div {
  border-radius: 50%; }

.bootstrap-datetimepicker-widget table td.day:hover > div, .bootstrap-datetimepicker-widget table td.hour:hover > div, .bootstrap-datetimepicker-widget table td.minute:hover > div, .bootstrap-datetimepicker-widget table td.second:hover > div {
  background: #E3E3E3;
  cursor: pointer; }

.bootstrap-datetimepicker-widget table td.old > div, .bootstrap-datetimepicker-widget table td.new > div {
  color: #cfcfca; }

.bootstrap-datetimepicker-widget table td.active > div, .bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: #68B3C8;
  color: #FFFFFF; }

.bootstrap-datetimepicker-widget table td.active.today:before > div {
  border-bottom-color: #FFFFFF; }

.bootstrap-datetimepicker-widget table td.disabled > div, .bootstrap-datetimepicker-widget table td.disabled:hover > div {
  background: none;
  color: #cfcfca;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center; }

.bootstrap-datetimepicker-widget table td span:hover {
  background: #E3E3E3; }

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #68B3C8;
  color: #FFFFFF; }

.bootstrap-datetimepicker-widget table td span.old {
  color: #cfcfca; }

.bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #cfcfca;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important; }

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px; }

.input-group.date .input-group-addon {
  cursor: pointer; }

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer; }

.bootstrap-datetimepicker-widget {
  opacity: 0;
  -webkit-transform: translate3d(0px, -10px, 0px);
  -moz-transform: translate3d(0, -10px, 0);
  -o-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0px, -10px, 0px);
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1); }
  .bootstrap-datetimepicker-widget.top {
    -webkit-transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0, 0px, 0);
    -o-transform: translate3d(0, 0px, 0);
    -ms-transform: translate3d(0, 0px, 0);
    transform: translate3d(0px, 0px, 0px); }
  .bootstrap-datetimepicker-widget.open {
    opacity: 1;
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.bootstrap-datetimepicker-widget.open.top {
  -webkit-transform: translate3d(0, -10px, 0);
  -moz-transform: translate3d(0, -10px, 0);
  -o-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }
  .bootstrap-datetimepicker-widget.open.top:before {
    border-top: 11px solid #DCD9D1;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    bottom: -12px; }
  .bootstrap-datetimepicker-widget.open.top:after {
    border-top: 11px solid #FFFFFF;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    bottom: -11px; }

.btn {
  box-sizing: border-box;
  border-width: 2px;
  font-size: 12px;
  font-weight: 600;
  padding: 0.5rem 18px;
  line-height: 1.75;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #66615B;
  border-color: #66615B;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }
  .btn.btn-border, .btn.btn-link {
    background-color: transparent; }
  .btn:hover, .btn:focus, .btn:active, .btn.active, .btn:active:focus, .btn:active:hover, .btn.active:focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled).active:focus,
  .show > .btn.dropdown-toggle {
    background-color: #403D39;
    color: #FFFFFF;
    border-color: #403D39;
    box-shadow: none; }
  .btn .caret {
    border-top-color: #FFFFFF; }
  .btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn.disabled:active, .btn.disabled.active, .btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn:disabled.focus, .btn:disabled:active, .btn:disabled.active, .btn[disabled], .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, .btn[disabled]:active, .btn[disabled].active,
  fieldset[disabled] .btn,
  fieldset[disabled] .btn:hover,
  fieldset[disabled] .btn:focus,
  fieldset[disabled] .btn.focus,
  fieldset[disabled] .btn:active,
  fieldset[disabled] .btn.active {
    background-color: #403D39;
    border-color: #403D39; }
  .btn.btn-link {
    color: #66615B; }
    .btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link:active, .btn.btn-link.active, .btn.btn-link:focus:active,
    .open > .btn.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #403D39 !important; }
    .btn.btn-link .caret {
      border-top-color: #66615B; }
  .btn .caret {
    border-top-color: #FFFFFF; }
  .btn:hover, .btn:focus {
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .btn[class*="btn-outline-"] {
    background-image: none;
    background-color: transparent; }
  .btn .nc-icon {
    position: relative;
    top: 2px; }

.btn-just-icon {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  padding: 8px; }
  .btn-just-icon.btn-sm {
    padding: 4px !important; }
  .btn-just-icon i {
    font-size: 16px;
    padding: 2px 0px; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -2px; }

.btn-primary {
  background-color: #51cbce;
  border-color: #51cbce;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle {
    background-color: #34b5b8;
    color: #FFFFFF;
    border-color: #34b5b8;
    box-shadow: none; }
  .btn-primary .caret {
    border-top-color: #FFFFFF; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary:disabled, .btn-primary:disabled:hover, .btn-primary:disabled:focus, .btn-primary:disabled.focus, .btn-primary:disabled:active, .btn-primary:disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: #34b5b8;
    border-color: #34b5b8; }
  .btn-primary.btn-link {
    color: #51cbce; }
    .btn-primary.btn-link:hover, .btn-primary.btn-link:focus, .btn-primary.btn-link:active, .btn-primary.btn-link.active, .btn-primary.btn-link:focus:active,
    .open > .btn-primary.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #34b5b8 !important; }
    .btn-primary.btn-link .caret {
      border-top-color: #51cbce; }
  .btn-primary .caret {
    border-top-color: #FFFFFF; }

.btn-success {
  background-color: #6bd098;
  border-color: #6bd098;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle {
    background-color: #44c47d;
    color: #FFFFFF;
    border-color: #44c47d;
    box-shadow: none; }
  .btn-success .caret {
    border-top-color: #FFFFFF; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success:disabled, .btn-success:disabled:hover, .btn-success:disabled:focus, .btn-success:disabled.focus, .btn-success:disabled:active, .btn-success:disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: #44c47d;
    border-color: #44c47d; }
  .btn-success.btn-link {
    color: #6bd098; }
    .btn-success.btn-link:hover, .btn-success.btn-link:focus, .btn-success.btn-link:active, .btn-success.btn-link.active, .btn-success.btn-link:focus:active,
    .open > .btn-success.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #44c47d !important; }
    .btn-success.btn-link .caret {
      border-top-color: #6bd098; }
  .btn-success .caret {
    border-top-color: #FFFFFF; }

.btn-info {
  background-color: #51bcda;
  border-color: #51bcda;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info:active:focus, .btn-info:active:hover, .btn-info.active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle {
    background-color: #2ba9cd;
    color: #FFFFFF;
    border-color: #2ba9cd;
    box-shadow: none; }
  .btn-info .caret {
    border-top-color: #FFFFFF; }
  .btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info:disabled, .btn-info:disabled:hover, .btn-info:disabled:focus, .btn-info:disabled.focus, .btn-info:disabled:active, .btn-info:disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background-color: #2ba9cd;
    border-color: #2ba9cd; }
  .btn-info.btn-link {
    color: #51bcda; }
    .btn-info.btn-link:hover, .btn-info.btn-link:focus, .btn-info.btn-link:active, .btn-info.btn-link.active, .btn-info.btn-link:focus:active,
    .open > .btn-info.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #2ba9cd !important; }
    .btn-info.btn-link .caret {
      border-top-color: #51bcda; }
  .btn-info .caret {
    border-top-color: #FFFFFF; }

.btn-warning {
  background-color: #fbc658;
  border-color: #fbc658;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning:active:focus, .btn-warning:active:hover, .btn-warning.active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle {
    background-color: #fab526;
    color: #FFFFFF;
    border-color: #fab526;
    box-shadow: none; }
  .btn-warning .caret {
    border-top-color: #FFFFFF; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning:disabled, .btn-warning:disabled:hover, .btn-warning:disabled:focus, .btn-warning:disabled.focus, .btn-warning:disabled:active, .btn-warning:disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: #fab526;
    border-color: #fab526; }
  .btn-warning.btn-link {
    color: #fbc658; }
    .btn-warning.btn-link:hover, .btn-warning.btn-link:focus, .btn-warning.btn-link:active, .btn-warning.btn-link.active, .btn-warning.btn-link:focus:active,
    .open > .btn-warning.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #fab526 !important; }
    .btn-warning.btn-link .caret {
      border-top-color: #fbc658; }
  .btn-warning .caret {
    border-top-color: #FFFFFF; }

.btn-danger {
  background-color: #f5593d;
  border-color: #f5593d;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle {
    background-color: #f33816;
    color: #FFFFFF;
    border-color: #f33816;
    box-shadow: none; }
  .btn-danger .caret {
    border-top-color: #FFFFFF; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger:disabled, .btn-danger:disabled:hover, .btn-danger:disabled:focus, .btn-danger:disabled.focus, .btn-danger:disabled:active, .btn-danger:disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: #f33816;
    border-color: #f33816; }
  .btn-danger.btn-link {
    color: #f5593d; }
    .btn-danger.btn-link:hover, .btn-danger.btn-link:focus, .btn-danger.btn-link:active, .btn-danger.btn-link.active, .btn-danger.btn-link:focus:active,
    .open > .btn-danger.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #f33816 !important; }
    .btn-danger.btn-link .caret {
      border-top-color: #f5593d; }
  .btn-danger .caret {
    border-top-color: #FFFFFF; }

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .btn-neutral:active:focus, .btn-neutral:active:hover, .btn-neutral.active:focus, .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active, .btn-neutral:not(:disabled):not(.disabled).active:focus,
  .show > .btn-neutral.dropdown-toggle {
    background-color: #403D39;
    color: #FFFFFF;
    border-color: #403D39;
    box-shadow: none; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: #403D39;
    border-color: #403D39; }
  .btn-neutral.btn-link {
    color: #FFFFFF; }
    .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .btn-neutral.btn-link:focus:active,
    .open > .btn-neutral.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #403D39 !important; }
    .btn-neutral.btn-link .caret {
      border-top-color: #FFFFFF; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }

.btn-outline-default {
  border-color: #66615B;
  color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-default:hover, .btn-outline-default:focus, .btn-outline-default:active, .btn-outline-default.active, .btn-outline-default:active:focus, .btn-outline-default:active:hover, .btn-outline-default.active:focus,
  .show > .btn-outline-default.dropdown-toggle {
    background-color: #66615B !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #66615B !important; }
    .btn-outline-default:hover .caret, .btn-outline-default:focus .caret, .btn-outline-default:active .caret, .btn-outline-default.active .caret, .btn-outline-default:active:focus .caret, .btn-outline-default:active:hover .caret, .btn-outline-default.active:focus .caret,
    .show > .btn-outline-default.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-default .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-default.disabled, .btn-outline-default.disabled:hover, .btn-outline-default.disabled:focus, .btn-outline-default.disabled.focus, .btn-outline-default.disabled:active, .btn-outline-default.disabled.active, .btn-outline-default:disabled, .btn-outline-default:disabled:hover, .btn-outline-default:disabled:focus, .btn-outline-default:disabled.focus, .btn-outline-default:disabled:active, .btn-outline-default:disabled.active, .btn-outline-default[disabled], .btn-outline-default[disabled]:hover, .btn-outline-default[disabled]:focus, .btn-outline-default[disabled].focus, .btn-outline-default[disabled]:active, .btn-outline-default[disabled].active,
  fieldset[disabled] .btn-outline-default,
  fieldset[disabled] .btn-outline-default:hover,
  fieldset[disabled] .btn-outline-default:focus,
  fieldset[disabled] .btn-outline-default.focus,
  fieldset[disabled] .btn-outline-default:active,
  fieldset[disabled] .btn-outline-default.active {
    background-color: transparent;
    border-color: #66615B; }

.btn-outline-primary {
  border-color: #51cbce;
  color: #51cbce;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary:active:focus, .btn-outline-primary:active:hover, .btn-outline-primary.active:focus,
  .show > .btn-outline-primary.dropdown-toggle {
    background-color: #51cbce !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #51cbce !important; }
    .btn-outline-primary:hover .caret, .btn-outline-primary:focus .caret, .btn-outline-primary:active .caret, .btn-outline-primary.active .caret, .btn-outline-primary:active:focus .caret, .btn-outline-primary:active:hover .caret, .btn-outline-primary.active:focus .caret,
    .show > .btn-outline-primary.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-primary .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-primary.disabled, .btn-outline-primary.disabled:hover, .btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.focus, .btn-outline-primary.disabled:active, .btn-outline-primary.disabled.active, .btn-outline-primary:disabled, .btn-outline-primary:disabled:hover, .btn-outline-primary:disabled:focus, .btn-outline-primary:disabled.focus, .btn-outline-primary:disabled:active, .btn-outline-primary:disabled.active, .btn-outline-primary[disabled], .btn-outline-primary[disabled]:hover, .btn-outline-primary[disabled]:focus, .btn-outline-primary[disabled].focus, .btn-outline-primary[disabled]:active, .btn-outline-primary[disabled].active,
  fieldset[disabled] .btn-outline-primary,
  fieldset[disabled] .btn-outline-primary:hover,
  fieldset[disabled] .btn-outline-primary:focus,
  fieldset[disabled] .btn-outline-primary.focus,
  fieldset[disabled] .btn-outline-primary:active,
  fieldset[disabled] .btn-outline-primary.active {
    background-color: transparent;
    border-color: #51cbce; }

.btn-outline-success {
  border-color: #6bd098;
  color: #6bd098;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success:active:focus, .btn-outline-success:active:hover, .btn-outline-success.active:focus,
  .show > .btn-outline-success.dropdown-toggle {
    background-color: #6bd098 !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #6bd098 !important; }
    .btn-outline-success:hover .caret, .btn-outline-success:focus .caret, .btn-outline-success:active .caret, .btn-outline-success.active .caret, .btn-outline-success:active:focus .caret, .btn-outline-success:active:hover .caret, .btn-outline-success.active:focus .caret,
    .show > .btn-outline-success.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-success .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-success.disabled, .btn-outline-success.disabled:hover, .btn-outline-success.disabled:focus, .btn-outline-success.disabled.focus, .btn-outline-success.disabled:active, .btn-outline-success.disabled.active, .btn-outline-success:disabled, .btn-outline-success:disabled:hover, .btn-outline-success:disabled:focus, .btn-outline-success:disabled.focus, .btn-outline-success:disabled:active, .btn-outline-success:disabled.active, .btn-outline-success[disabled], .btn-outline-success[disabled]:hover, .btn-outline-success[disabled]:focus, .btn-outline-success[disabled].focus, .btn-outline-success[disabled]:active, .btn-outline-success[disabled].active,
  fieldset[disabled] .btn-outline-success,
  fieldset[disabled] .btn-outline-success:hover,
  fieldset[disabled] .btn-outline-success:focus,
  fieldset[disabled] .btn-outline-success.focus,
  fieldset[disabled] .btn-outline-success:active,
  fieldset[disabled] .btn-outline-success.active {
    background-color: transparent;
    border-color: #6bd098; }

.btn-outline-info {
  border-color: #51bcda;
  color: #51bcda;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info:active:focus, .btn-outline-info:active:hover, .btn-outline-info.active:focus,
  .show > .btn-outline-info.dropdown-toggle {
    background-color: #51bcda !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #51bcda !important; }
    .btn-outline-info:hover .caret, .btn-outline-info:focus .caret, .btn-outline-info:active .caret, .btn-outline-info.active .caret, .btn-outline-info:active:focus .caret, .btn-outline-info:active:hover .caret, .btn-outline-info.active:focus .caret,
    .show > .btn-outline-info.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-info .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-info.disabled, .btn-outline-info.disabled:hover, .btn-outline-info.disabled:focus, .btn-outline-info.disabled.focus, .btn-outline-info.disabled:active, .btn-outline-info.disabled.active, .btn-outline-info:disabled, .btn-outline-info:disabled:hover, .btn-outline-info:disabled:focus, .btn-outline-info:disabled.focus, .btn-outline-info:disabled:active, .btn-outline-info:disabled.active, .btn-outline-info[disabled], .btn-outline-info[disabled]:hover, .btn-outline-info[disabled]:focus, .btn-outline-info[disabled].focus, .btn-outline-info[disabled]:active, .btn-outline-info[disabled].active,
  fieldset[disabled] .btn-outline-info,
  fieldset[disabled] .btn-outline-info:hover,
  fieldset[disabled] .btn-outline-info:focus,
  fieldset[disabled] .btn-outline-info.focus,
  fieldset[disabled] .btn-outline-info:active,
  fieldset[disabled] .btn-outline-info.active {
    background-color: transparent;
    border-color: #51bcda; }

.btn-outline-warning {
  border-color: #fbc658;
  color: #fbc658;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning:active:focus, .btn-outline-warning:active:hover, .btn-outline-warning.active:focus,
  .show > .btn-outline-warning.dropdown-toggle {
    background-color: #fbc658 !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #fbc658 !important; }
    .btn-outline-warning:hover .caret, .btn-outline-warning:focus .caret, .btn-outline-warning:active .caret, .btn-outline-warning.active .caret, .btn-outline-warning:active:focus .caret, .btn-outline-warning:active:hover .caret, .btn-outline-warning.active:focus .caret,
    .show > .btn-outline-warning.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-warning .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-warning.disabled, .btn-outline-warning.disabled:hover, .btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.focus, .btn-outline-warning.disabled:active, .btn-outline-warning.disabled.active, .btn-outline-warning:disabled, .btn-outline-warning:disabled:hover, .btn-outline-warning:disabled:focus, .btn-outline-warning:disabled.focus, .btn-outline-warning:disabled:active, .btn-outline-warning:disabled.active, .btn-outline-warning[disabled], .btn-outline-warning[disabled]:hover, .btn-outline-warning[disabled]:focus, .btn-outline-warning[disabled].focus, .btn-outline-warning[disabled]:active, .btn-outline-warning[disabled].active,
  fieldset[disabled] .btn-outline-warning,
  fieldset[disabled] .btn-outline-warning:hover,
  fieldset[disabled] .btn-outline-warning:focus,
  fieldset[disabled] .btn-outline-warning.focus,
  fieldset[disabled] .btn-outline-warning:active,
  fieldset[disabled] .btn-outline-warning.active {
    background-color: transparent;
    border-color: #fbc658; }

.btn-outline-danger {
  border-color: #f5593d;
  color: #f5593d;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger:active:focus, .btn-outline-danger:active:hover, .btn-outline-danger.active:focus,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f5593d !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #f5593d !important; }
    .btn-outline-danger:hover .caret, .btn-outline-danger:focus .caret, .btn-outline-danger:active .caret, .btn-outline-danger.active .caret, .btn-outline-danger:active:focus .caret, .btn-outline-danger:active:hover .caret, .btn-outline-danger.active:focus .caret,
    .show > .btn-outline-danger.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-danger .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-danger.disabled, .btn-outline-danger.disabled:hover, .btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.focus, .btn-outline-danger.disabled:active, .btn-outline-danger.disabled.active, .btn-outline-danger:disabled, .btn-outline-danger:disabled:hover, .btn-outline-danger:disabled:focus, .btn-outline-danger:disabled.focus, .btn-outline-danger:disabled:active, .btn-outline-danger:disabled.active, .btn-outline-danger[disabled], .btn-outline-danger[disabled]:hover, .btn-outline-danger[disabled]:focus, .btn-outline-danger[disabled].focus, .btn-outline-danger[disabled]:active, .btn-outline-danger[disabled].active,
  fieldset[disabled] .btn-outline-danger,
  fieldset[disabled] .btn-outline-danger:hover,
  fieldset[disabled] .btn-outline-danger:focus,
  fieldset[disabled] .btn-outline-danger.focus,
  fieldset[disabled] .btn-outline-danger:active,
  fieldset[disabled] .btn-outline-danger.active {
    background-color: transparent;
    border-color: #f5593d; }

.btn-outline-neutral {
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active, .btn-outline-neutral.active, .btn-outline-neutral:active:focus, .btn-outline-neutral:active:hover, .btn-outline-neutral.active:focus,
  .show > .btn-outline-neutral.dropdown-toggle {
    background-color: #FFFFFF !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #FFFFFF !important; }
    .btn-outline-neutral:hover .caret, .btn-outline-neutral:focus .caret, .btn-outline-neutral:active .caret, .btn-outline-neutral.active .caret, .btn-outline-neutral:active:focus .caret, .btn-outline-neutral:active:hover .caret, .btn-outline-neutral.active:focus .caret,
    .show > .btn-outline-neutral.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-neutral.disabled, .btn-outline-neutral.disabled:hover, .btn-outline-neutral.disabled:focus, .btn-outline-neutral.disabled.focus, .btn-outline-neutral.disabled:active, .btn-outline-neutral.disabled.active, .btn-outline-neutral:disabled, .btn-outline-neutral:disabled:hover, .btn-outline-neutral:disabled:focus, .btn-outline-neutral:disabled.focus, .btn-outline-neutral:disabled:active, .btn-outline-neutral:disabled.active, .btn-outline-neutral[disabled], .btn-outline-neutral[disabled]:hover, .btn-outline-neutral[disabled]:focus, .btn-outline-neutral[disabled].focus, .btn-outline-neutral[disabled]:active, .btn-outline-neutral[disabled].active,
  fieldset[disabled] .btn-outline-neutral,
  fieldset[disabled] .btn-outline-neutral:hover,
  fieldset[disabled] .btn-outline-neutral:focus,
  fieldset[disabled] .btn-outline-neutral.focus,
  fieldset[disabled] .btn-outline-neutral:active,
  fieldset[disabled] .btn-outline-neutral.active {
    background-color: transparent;
    border-color: #FFFFFF; }
  .btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active {
    color: #403D39 !important;
    background-color: #FFFFFF; }

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #66615B; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .btn-neutral:active:focus, .btn-neutral:active:hover, .btn-neutral.active:focus, .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active, .btn-neutral:not(:disabled):not(.disabled).active:focus,
  .show > .btn-neutral.dropdown-toggle {
    background-color: #FFFFFF;
    color: #FFFFFF;
    border-color: #FFFFFF;
    box-shadow: none; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-neutral.btn-link {
    color: #FFFFFF; }
    .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .btn-neutral.btn-link:focus:active,
    .open > .btn-neutral.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #FFFFFF !important; }
    .btn-neutral.btn-link .caret {
      border-top-color: #FFFFFF; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active {
    color: #403D39 !important; }
  .btn-neutral.btn-border:hover, .btn-neutral.btn-border:focus {
    color: #66615B; }
  .btn-neutral.btn-border:active, .btn-neutral.btn-border.active,
  .open > .btn-neutral.btn-border.dropdown-toggle {
    background-color: #FFFFFF;
    color: #66615B; }
  .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active {
    color: #FFFFFF !important; }
  .btn-neutral.btn-link:focus:active {
    color: #403D39 !important; }
  .btn-neutral.btn-link:active, .btn-neutral.btn-link.active {
    background-color: transparent; }

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.btn-link {
  border-color: transparent !important;
  padding: 7px 18px; }
  .btn-link:hover, .btn-link:focus, .btn-link:active {
    text-decoration: none;
    border-color: transparent; }
  .btn-link.btn-icon {
    padding: 7px; }

.btn-lg {
  font-size: 14px;
  padding: 11px 30px; }
  .btn-lg.btn-simple {
    padding: 13px 30px; }

.btn-sm {
  font-size: 12px;
  padding: 4px 10px; }
  .btn-sm.btn-simple {
    padding: 6px 10px; }

.btn-wd {
  min-width: 140px; }

.btn-group.select {
  width: 100%; }

.btn-group.select .btn {
  text-align: left; }

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px; }

.btn-just-icon.btn-sm {
  height: 30px;
  width: 30px;
  min-width: 30px;
  padding: 0; }
  .btn-just-icon.btn-sm i {
    font-size: 12px;
    top: 1px !important; }

.btn-just-icon.btn-lg {
  height: 50px;
  width: 50px;
  min-width: 50px;
  padding: 13px; }
  .btn-just-icon.btn-lg i {
    font-size: 18px;
    padding: 0; }

.btn-just-icon.btn-link {
  padding: 8px !important; }

.btn-round {
  border-radius: 30px; }

.btn.btn-link:focus {
  box-shadow: none !important;
  text-decoration: none; }

.column .btn-link {
  padding: 7px 0; }

.share-buttons .btn-outline-default {
  margin-top: 24px; }

.btn-group.select {
  overflow: visible !important; }

.media .media-body .media-footer .btn-neutral {
  margin: 15px 3px;
  font-size: 14px; }
  .media .media-body .media-footer .btn-neutral i {
    margin-right: 0 !important; }

::-moz-placeholder {
  color: #9A9A9A; }

:-ms-input-placeholder {
  color: #9A9A9A; }

::-webkit-input-placeholder {
  color: #9A9A9A; }

.form-control:focus + .input-group-text {
  border: 1px solid black; }

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #66615b;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  padding-right: 0 !important;
  padding: 7px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
    .form-control:focus + .input-group-prepend .input-group-text,
    .form-control:focus + .input-group-append .input-group-text {
      border: 1px solid #ccc;
      border-left: none; }
  .form-control.no-border {
    border: medium none !important; }
    .form-control.no-border:focus + .input-group-prepend .input-group-text,
    .form-control.no-border:focus + .input-group-append .input-group-text {
      border: none; }
  .has-success .form-control,
  .has-error .form-control,
  .has-success .form-control:focus,
  .has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .has-success .form-control {
    border: 1px solid #ccc;
    color: #66615b; }
    .has-success .form-control.form-control-success {
      padding-right: 2.5em !important; }
  .has-success .form-control:focus {
    border: 1px solid #6bd098;
    color: #6bd098; }
  .has-danger .form-control {
    background-color: #FFC0A4;
    border: 1px solid #f5593d;
    color: #f5593d; }
    .has-danger .form-control.form-control-danger {
      padding-right: 2.5em !important; }
  .has-danger .form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #f5593d; }
  .form-control + .form-control-feedback {
    border-radius: 6px;
    font-size: 14px;
    color: #f5593d;
    font-size: .8rem;
    position: absolute;
    top: 100%;
    padding-left: 12px;
    vertical-align: middle; }
  .open .form-control {
    border-radius: 4px 4px 0 0;
    border-bottom-color: transparent; }
  .form-control.form-control-lg {
    height: 55px;
    padding: 11px 13px;
    font-size: 1.25rem;
    line-height: 1.5; }
  .form-control.form-control-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    height: 31px; }

.has-error .form-control-feedback, .has-error .control-label {
  color: #f5593d; }

.has-success .form-control-feedback, .has-success .control-label {
  color: #6bd098; }

.input-group-prepend + .form-control {
  margin-left: -1px;
  border-left: none; }

.form-control + .input-group-append .input-group-text {
  border-right: 1px solid #ddd; }

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-right: none; }

.input-group-text {
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #ddd;
  border-right: none; }
  .has-success .input-group-text,
  .has-error .input-group-text {
    background-color: #FFFFFF; }
  .has-error .form-control:focus + .input-group-text {
    color: #f5593d; }
  .has-success .form-control:focus + .input-group-text {
    color: #6bd098; }
  .form-control:focus + .input-group-text,
  .form-control:focus ~ .input-group-text {
    background-color: #FFFFFF; }

.input-group.no-border .input-group-text {
  border: 0; }

textarea.form-control {
  padding: 10px 18px;
  height: auto; }

.form-group {
  position: relative; }

.register-form .form-control {
  border: 1px solid transparent !important; }

.register-form .input-group .form-control {
  margin-left: -1px; }

.register-form .input-group-prepend,
.register-form .input-group-append {
  border: 1px solid transparent !important; }

#inputs .input-group {
  margin-bottom: 1rem; }

.card-form-horizontal .card-body .form-group {
  margin-bottom: 0; }

.from-check,
.form-check-radio {
  margin-bottom: 12px;
  position: relative; }

.form-check {
  padding-left: 0;
  margin-bottom: .5rem; }
  .form-check .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0; }
  .form-check .form-check-sign::before,
  .form-check .form-check-sign::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    cursor: pointer;
    border-radius: 6px;
    top: 0;
    background-color: #AAA7A4;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear; }
  .form-check .form-check-sign::after {
    font-family: 'FontAwesome';
    content: "\f00c";
    top: -1px;
    text-align: center;
    font-size: 15px;
    opacity: 0;
    color: #FFF;
    border: 0;
    background-color: inherit; }
  .form-check.disabled .form-check-label {
    color: #9A9A9A;
    opacity: .5;
    cursor: not-allowed; }

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden; }

.form-check input[type="checkbox"]:checked + .form-check-sign::after {
  opacity: 1; }

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after {
  cursor: not-allowed; }

.form-check .form-check-label input[type="checkbox"]:disabled + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign {
  pointer-events: none !important; }

.form-check-radio {
  margin-left: -3px; }
  .form-check-radio .form-check-label {
    padding-left: 2rem; }
  .form-check-radio.disabled .form-check-label {
    color: #9A9A9A;
    opacity: .5;
    cursor: not-allowed; }

.form-check-radio .form-check-sign::before {
  font-family: 'FontAwesome';
  content: "\f10c";
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  opacity: .50;
  left: 5px;
  top: -5px; }

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before {
  background-color: #66615B; }

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  content: " ";
  display: block; }

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  font-family: 'FontAwesome';
  content: "\f192";
  top: -5px;
  position: absolute;
  left: 5px;
  opacity: 1;
  font-size: 22px; }

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  opacity: 1; }

.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
  color: #9A9A9A; }

.progress {
  background-color: #DDDDDD;
  border-radius: 3px;
  box-shadow: none;
  height: 8px; }

.progress-thin {
  height: 4px; }

.progress-bar {
  background-color: #51cbce; }

.progress-bar-primary {
  background-color: #51cbce; }

.progress-bar-info {
  background-color: #51bcda; }

.progress-bar-success {
  background-color: #6bd098; }

.progress-bar-warning {
  background-color: #fbc658; }

.progress-bar-danger {
  background-color: #f5593d; }

/*           badges & Progress-bar              */
.badge {
  padding: 0.4em 0.9em;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 0.75em;
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 5px; }
  .badge:focus {
    outline: none; }
  .badge span[data-role="remove"]:focus {
    outline: none; }

.badge-primary {
  background-color: #51cbce; }

.badge-info {
  background-color: #51bcda; }

.badge-success {
  background-color: #6bd098; }

.badge-warning {
  background-color: #fbc658; }

.badge-danger {
  background-color: #f5593d; }

.badge-default {
  background-color: #66615B; }

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: transparent;
  border: 2px solid #f5593d;
  border-radius: 20px;
  color: #f5593d;
  height: 36px;
  margin: 0 2px;
  min-width: 36px;
  padding: 8px 12px;
  font-weight: 600; }

.nav-pills-default > li > a,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: #66615B;
  border-color: #66615B;
  color: #FFFFFF; }

.nav-pills-primary > li > a,
.pagination-primary > li > a,
.pagination-primary > li > span,
.pagination-primary > li:first-child > a,
.pagination-primary > li:first-child > span,
.pagination-primary > li:last-child > a,
.pagination-primary > li:last-child > span {
  border: 2px solid #51cbce;
  color: #51cbce; }

.nav-pills-primary > li.active > a,
.nav-pills-primary > li.active > a:hover,
.nav-pills-primary > li.active > a:focus,
.pagination-primary > li > a:hover,
.pagination-primary > li > a:focus,
.pagination-primary > li > a:active,
.pagination-primary > li.active > a,
.pagination-primary > li.active > span,
.pagination-primary > li.active > a:hover,
.pagination-primary > li.active > span:hover,
.pagination-primary > li.active > a:focus,
.pagination-primary > li.active > span:focus {
  background-color: #51cbce !important;
  border-color: #51cbce !important;
  color: #FFFFFF; }

.nav-pills-info > li > a,
.pagination-info > li > a,
.pagination-info > li > span,
.pagination-info > li:first-child > a,
.pagination-info > li:first-child > span,
.pagination-info > li:last-child > a,
.pagination-info > li:last-child > span {
  border: 2px solid #51bcda;
  color: #51bcda; }

.nav-pills-info > li.active > a,
.nav-pills-info > li.active > a:hover,
.nav-pills-info > li.active > a:focus,
.pagination-info > li > a:hover,
.pagination-info > li > a:focus,
.pagination-info > li > a:active,
.pagination-info > li.active > a,
.pagination-info > li.active > span,
.pagination-info > li.active > a:hover,
.pagination-info > li.active > span:hover,
.pagination-info > li.active > a:focus,
.pagination-info > li.active > span:focus {
  background-color: #51bcda !important;
  border-color: #51bcda !important;
  color: #FFFFFF; }

.nav-pills-success > li > a,
.pagination-success > li > a,
.pagination-success > li > span,
.pagination-success > li:first-child > a,
.pagination-success > li:first-child > span,
.pagination-success > li:last-child > a,
.pagination-success > li:last-child > span {
  border: 2px solid #6bd098;
  color: #6bd098; }

.nav-pills-success > li.active > a,
.nav-pills-success > li.active > a:hover,
.nav-pills-success > li.active > a:focus,
.pagination-success > li > a:hover,
.pagination-success > li > a:focus,
.pagination-success > li > a:active,
.pagination-success > li.active > a,
.pagination-success > li.active > span,
.pagination-success > li.active > a:hover,
.pagination-success > li.active > span:hover,
.pagination-success > li.active > a:focus,
.pagination-success > li.active > span:focus {
  background-color: #6bd098 !important;
  border-color: #6bd098 !important;
  color: #FFFFFF; }

.nav-pills-warning > li > a,
.pagination-warning > li > a,
.pagination-warning > li > span,
.pagination-warning > li:first-child > a,
.pagination-warning > li:first-child > span,
.pagination-warning > li:last-child > a,
.pagination-warning > li:last-child > span {
  border: 2px solid #fbc658;
  color: #fbc658; }

.nav-pills-warning > li.active > a,
.nav-pills-warning > li.active > a:hover,
.nav-pills-warning > li.active > a:focus,
.pagination-warning > li > a:hover,
.pagination-warning > li > a:focus,
.pagination-warning > li > a:active,
.pagination-warning > li.active > a,
.pagination-warning > li.active > span,
.pagination-warning > li.active > a:hover,
.pagination-warning > li.active > span:hover,
.pagination-warning > li.active > a:focus,
.pagination-warning > li.active > span:focus {
  background-color: #fbc658 !important;
  border-color: #fbc658 !important;
  color: #FFFFFF; }

.nav-pills-danger > li > a,
.pagination-danger > li > a,
.pagination-danger > li > span,
.pagination-danger > li:first-child > a,
.pagination-danger > li:first-child > span,
.pagination-danger > li:last-child > a,
.pagination-danger > li:last-child > span {
  border: 2px solid #f5593d;
  color: #f5593d; }

.nav-pills-danger > li.active > a,
.nav-pills-danger > li.active > a:hover,
.nav-pills-danger > li.active > a:focus,
.pagination-danger > li > a:hover,
.pagination-danger > li > a:focus,
.pagination-danger > li > a:active,
.pagination-danger > li.active > a,
.pagination-danger > li.active > span,
.pagination-danger > li.active > a:hover,
.pagination-danger > li.active > span:hover,
.pagination-danger > li.active > a:focus,
.pagination-danger > li.active > span:focus {
  background-color: #f5593d !important;
  border-color: #f5593d !important;
  color: #FFFFFF; }

.nav-text, .nav-icons {
  margin: 0 0 10px 0; }
  .nav-text > li > a, .nav-icons > li > a {
    display: block;
    padding: 0px 18px;
    color: #9A9A9A;
    text-align: center;
    opacity: 0.8;
    filter: alpha(opacity=80); }
    .nav-text > li > a:hover, .nav-text > li > a:focus, .nav-icons > li > a:hover, .nav-icons > li > a:focus {
      background-color: transparent;
      opacity: 1;
      filter: alpha(opacity=100); }
  .nav-text > li:first-child a, .nav-icons > li:first-child a {
    padding-left: 0; }
  .nav-text > li.active a, .nav-icons > li.active a {
    color: #51bcda; }

.nav-icons > li {
  display: inline-block; }
  .nav-icons > li > a {
    padding: 0 10px;
    margin-bottom: 10px; }
  .nav-icons > li > a i {
    font-size: 1.6em;
    margin-bottom: 10px;
    width: 1.6em; }

.nav-icons.nav-stacked > li {
  display: block; }
  .nav-icons.nav-stacked > li > a {
    margin-bottom: 20px; }

.nav-blue > li.active a {
  color: #51cbce; }

.nav-azure > li.active a {
  color: #51bcda; }

.nav-green > li.active a {
  color: #6bd098; }

.nav-orange > li.active a {
  color: #fbc658; }

.nav-red > li.active a {
  color: #f5593d; }

.nav-text {
  margin: 0 0 10px 0; }
  .nav-text > li > a {
    font-size: 0.9em;
    text-transform: uppercase;
    padding: 3px 0;
    text-align: left;
    font-weight: 500; }
  .nav-text > li:first-child > a {
    padding-top: 0; }
  .nav-text h4 {
    margin-top: 0; }

.nav-text:not(.nav-stacked) > li {
  display: inline-block; }

.nav-text:not(.nav-stacked) > li > a {
  margin-right: 15px; }

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; }

.nav-pills-default .nav-item.show .nav-link,
.nav-pills-default .nav-link.active {
  background-color: #66615B !important; }

.nav-pills-primary .nav-item.show .nav-link,
.nav-pills-primary .nav-link.active {
  background-color: #51cbce !important; }

.nav-pills-info .nav-item.show .nav-link,
.nav-pills-info .nav-link.active {
  background-color: #51bcda !important; }

.nav-pills-warning .nav-item.show .nav-link,
.nav-pills-warning .nav-link.active {
  background-color: #fbc658 !important; }

.nav-pills-success .nav-item.show .nav-link,
.nav-pills-success .nav-link.active {
  background-color: #6bd098 !important; }

.nav-pills-danger .nav-item.show .nav-link,
.nav-pills-danger .nav-link.active {
  background-color: #f5593d !important; }

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: #f5593d;
  border-color: #f5593d;
  color: #FFFFFF; }

.page-item.active .page-link {
  background-color: #f5593d;
  color: white;
  border-color: #f5593d; }

.nav-pills .nav-link {
  border-radius: 0; }

.pagination .page-link {
  box-shadow: none; }

/*           Font Smoothing      */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, a, .td-name, td, button, input, select, textarea {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Didact Gothic", Arial, sans-serif;
  font-weight: 300; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: 30px 0 0; }

h1, .h1 {
  font-size: 3.6em; }

h2, .h2 {
  font-size: 2.8em; }

h3, .h3 {
  font-size: 1.825em;
  line-height: 1.4;
  margin: 20px 0 0px; }

h4, .h4 {
  font-size: 1.6em;
  line-height: 1.2em; }

h5, .h5 {
  font-size: 1.35em;
  line-height: 1.4em; }

h6, .h6 {
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em; }

p {
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 5px; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #9A9A9A;
  line-height: 1.5em; }

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
  font-size: 60%; }

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-family: "Didact Gothic", Arial, sans-serif; }
  .title,
  .title a,
  .card-title,
  .card-title a,
  .info-title,
  .info-title a,
  .footer-brand,
  .footer-brand a,
  .footer-big h5,
  .footer-big h5 a,
  .footer-big h4,
  .footer-big h4 a,
  .media .media-heading,
  .media .media-heading a {
    color: #333333;
    text-decoration: none; }

.title-uppercase {
  text-transform: uppercase; }

.description {
  color: #9A9A9A; }

blockquote small {
  font-style: normal; }

.text-muted {
  color: #DDDDDD; }

.text-primary, .text-primary:hover {
  color: #51cbce !important; }

.text-info, .text-info:hover {
  color: #51bcda !important; }

.text-success, .text-success:hover {
  color: #6bd098 !important; }

.text-warning, .text-warning:hover {
  color: #fbc658 !important; }

.text-danger, .text-danger:hover {
  color: #f5593d !important; }

.glyphicon {
  line-height: 1; }

.heart {
  color: #EB5E28;
  animation: heathing 1s ease infinite; }

@keyframes heathing {
  0% {
    transform: scale(0.75); }
  20% {
    transform: scale(1); }
  40% {
    transform: scale(0.75); }
  60% {
    transform: scale(1); }
  80% {
    transform: scale(0.75); }
  100% {
    transform: scale(0.75); } }

.footer .credits,
.footer-nav {
  line-height: 85px; }

.footer .btn {
  margin-bottom: 0; }

.blockquote {
  border-left: 0 none;
  border-bottom: 1px solid #CCC5B9;
  border-top: 1px solid #CCC5B9;
  font-weight: 300;
  margin: 15px 0 10px;
  text-align: center; }

.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px; }

.title.text-center {
  margin-bottom: 50px; }

/*     General overwrite     */
body {
  color: #66615b;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Didact Gothic', "Helvetica", Arial, sans-serif; }

a {
  color: #51bcda; }
  a:hover, a:focus {
    color: #2ba9cd;
    text-decoration: none; }

hr {
  border-color: #F1EAE0; }

.icon {
  fill: #66615b; }

.fa-base {
  font-size: 1.25em !important; }

a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0; }

.ui-slider-handle:focus,
.navbar-toggle {
  outline: 0 !important; }

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert,
.carousel-control.right,
.carousel-control.left {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.tagsinput .tag,
.tagsinput-remove-link,
.filter,
.btn-hover,
[data-toggle="collapse"] i,
.animation-transition-fast,
.dropdown-menu .dropdown-item {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }

.btn-morphing .fa,
.btn-morphing .circle,
.gsdk-collapse {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.fa {
  width: 18px;
  text-align: center; }

.margin-top {
  margin-top: 50px; }

.iframe-container iframe {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

/*       CT colors          */
.ct-blue {
  color: #51cbce; }

.ct-azure {
  color: #51bcda; }

.ct-green {
  color: #6bd098; }

.ct-orange {
  color: #fbc658; }

.ct-red {
  color: #f5593d; }

.pagination .page-item .page-link .fa {
  width: auto;
  font-weight: 600; }

.bg-primary {
  background-color: #6dd3d6 !important; }

.bg-info {
  background-color: #6ec7e0 !important; }

.bg-success {
  background-color: #86d9ab !important; }

.bg-warning {
  background-color: #fcd27b !important; }

.bg-danger {
  background-color: #f7765f !important; }

.wrapper {
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  left: 0;
  background-color: #FFFFFF;
  position: relative;
  z-index: 1; }

.heart {
  color: #EB5E28;
  animation: heathing 1s ease infinite; }

@keyframes heathing {
  0% {
    transform: scale(0.75); }
  20% {
    transform: scale(1); }
  40% {
    transform: scale(0.75); }
  60% {
    transform: scale(1); }
  80% {
    transform: scale(0.75); }
  100% {
    transform: scale(0.75); } }

.bd-docs .page-header {
  min-height: 50vh; }

.offline-doc .navbar.navbar-transparent {
  padding-top: 25px;
  border-bottom: none; }
  .offline-doc .navbar.navbar-transparent .navbar-minimize {
    display: none; }
  .offline-doc .navbar.navbar-transparent .navbar-brand,
  .offline-doc .navbar.navbar-transparent .collapse .navbar-nav .nav-link {
    color: #FFFFFF !important; }

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  z-index: 3; }
  .offline-doc .footer nav > ul a:not(.btn),
  .offline-doc .footer .copyright {
    color: #FFFFFF !important; }

.offline-doc .page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1; }

.offline-doc .page-header .container {
  z-index: 3; }

.offline-doc .page-header:after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.main .section:first-of-type {
  position: relative;
  z-index: 2; }

/*           Labels & Progress-bar              */
.label {
  padding: 0.2em 0.6em;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 3px;
  line-height: 17px; }

.label-primary {
  background-color: #51cbce; }

.label-info {
  background-color: #51bcda; }

.label-success {
  background-color: #6bd098; }

.label-warning {
  background-color: #fbc658; }

.label-danger {
  background-color: #f5593d; }

.label-default {
  background-color: #66615B; }

/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url("../fonts/nucleo-icons.eot");
  src: url("../fonts/nucleo-icons.eot") format("embedded-opentype"), url("../fonts/nucleo-icons.woff2") format("woff2"), url("../fonts/nucleo-icons.woff") format("woff"), url("../fonts/nucleo-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%; }

.nc-icon.x2 {
  font-size: 2em; }

.nc-icon.x3 {
  font-size: 3em; }

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee; }

.nc-icon.circle {
  border-radius: 50%; }

/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.nc-icon-ul > li {
  position: relative; }

.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center; }

.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em; }

.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em; }

/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear; }

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

/*------------------------
	font icons
-------------------------*/
.nc-air-baloon::before {
  content: "\ea01"; }

.nc-album-2::before {
  content: "\ea02"; }

.nc-alert-circle-i::before {
  content: "\ea04"; }

.nc-align-center::before {
  content: "\ea03"; }

.nc-align-left-2::before {
  content: "\ea05"; }

.nc-ambulance::before {
  content: "\ea06"; }

.nc-app::before {
  content: "\ea07"; }

.nc-atom::before {
  content: "\ea08"; }

.nc-badge::before {
  content: "\ea09"; }

.nc-bag-16::before {
  content: "\ea0a"; }

.nc-bank::before {
  content: "\ea0b"; }

.nc-basket::before {
  content: "\ea0c"; }

.nc-bell-55::before {
  content: "\ea0d"; }

.nc-bold::before {
  content: "\ea0e"; }

.nc-book-bookmark::before {
  content: "\ea0f"; }

.nc-bookmark-2::before {
  content: "\ea10"; }

.nc-box-2::before {
  content: "\ea11"; }

.nc-box::before {
  content: "\ea12"; }

.nc-briefcase-24::before {
  content: "\ea13"; }

.nc-bulb-63::before {
  content: "\ea14"; }

.nc-bullet-list-67::before {
  content: "\ea15"; }

.nc-bus-front-12::before {
  content: "\ea16"; }

.nc-button-pause::before {
  content: "\ea17"; }

.nc-button-play::before {
  content: "\ea18"; }

.nc-button-power::before {
  content: "\ea19"; }

.nc-calendar-60::before {
  content: "\ea1a"; }

.nc-camera-compact::before {
  content: "\ea1b"; }

.nc-caps-small::before {
  content: "\ea1c"; }

.nc-cart-simple::before {
  content: "\ea1d"; }

.nc-chart-bar-32::before {
  content: "\ea1e"; }

.nc-chart-pie-36::before {
  content: "\ea1f"; }

.nc-chat-33::before {
  content: "\ea20"; }

.nc-check-2::before {
  content: "\ea21"; }

.nc-circle-10::before {
  content: "\ea22"; }

.nc-cloud-download-93::before {
  content: "\ea23"; }

.nc-cloud-upload-94::before {
  content: "\ea24"; }

.nc-compass-05::before {
  content: "\ea25"; }

.nc-controller-modern::before {
  content: "\ea26"; }

.nc-credit-card::before {
  content: "\ea27"; }

.nc-delivery-fast::before {
  content: "\ea28"; }

.nc-diamond::before {
  content: "\ea29"; }

.nc-email-85::before {
  content: "\ea2a"; }

.nc-favourite-28::before {
  content: "\ea2b"; }

.nc-glasses-2::before {
  content: "\ea2c"; }

.nc-globe-2::before {
  content: "\ea2d"; }

.nc-globe::before {
  content: "\ea2e"; }

.nc-hat-3::before {
  content: "\ea2f"; }

.nc-headphones::before {
  content: "\ea30"; }

.nc-html5::before {
  content: "\ea31"; }

.nc-image::before {
  content: "\ea32"; }

.nc-istanbul::before {
  content: "\ea33"; }

.nc-key-25::before {
  content: "\ea34"; }

.nc-laptop::before {
  content: "\ea35"; }

.nc-layout-11::before {
  content: "\ea36"; }

.nc-lock-circle-open::before {
  content: "\ea37"; }

.nc-map-big::before {
  content: "\ea38"; }

.nc-minimal-down::before {
  content: "\ea39"; }

.nc-minimal-left::before {
  content: "\ea3a"; }

.nc-minimal-right::before {
  content: "\ea3b"; }

.nc-minimal-up::before {
  content: "\ea3c"; }

.nc-mobile::before {
  content: "\ea3d"; }

.nc-money-coins::before {
  content: "\ea3e"; }

.nc-note-03::before {
  content: "\ea3f"; }

.nc-palette::before {
  content: "\ea40"; }

.nc-paper::before {
  content: "\ea41"; }

.nc-pin-3::before {
  content: "\ea42"; }

.nc-planet::before {
  content: "\ea43"; }

.nc-refresh-69::before {
  content: "\ea44"; }

.nc-ruler-pencil::before {
  content: "\ea45"; }

.nc-satisfied::before {
  content: "\ea46"; }

.nc-scissors::before {
  content: "\ea47"; }

.nc-send::before {
  content: "\ea48"; }

.nc-settings-gear-65::before {
  content: "\ea49"; }

.nc-settings::before {
  content: "\ea4a"; }

.nc-share-66::before {
  content: "\ea4b"; }

.nc-shop::before {
  content: "\ea4c"; }

.nc-simple-add::before {
  content: "\ea4d"; }

.nc-simple-delete::before {
  content: "\ea4e"; }

.nc-simple-remove::before {
  content: "\ea4f"; }

.nc-single-02::before {
  content: "\ea50"; }

.nc-single-copy-04::before {
  content: "\ea51"; }

.nc-sound-wave::before {
  content: "\ea52"; }

.nc-spaceship::before {
  content: "\ea53"; }

.nc-sun-fog-29::before {
  content: "\ea54"; }

.nc-support-17::before {
  content: "\ea55"; }

.nc-tablet-2::before {
  content: "\ea56"; }

.nc-tag-content::before {
  content: "\ea57"; }

.nc-tap-01::before {
  content: "\ea58"; }

.nc-tie-bow::before {
  content: "\ea59"; }

.nc-tile-56::before {
  content: "\ea5a"; }

.nc-time-alarm::before {
  content: "\ea5b"; }

.nc-touch-id::before {
  content: "\ea5c"; }

.nc-trophy::before {
  content: "\ea5d"; }

.nc-tv-2::before {
  content: "\ea5e"; }

.nc-umbrella-13::before {
  content: "\ea5f"; }

.nc-user-run::before {
  content: "\ea60"; }

.nc-vector::before {
  content: "\ea61"; }

.nc-watch-time::before {
  content: "\ea62"; }

.nc-world-2::before {
  content: "\ea63"; }

.nc-zoom-split::before {
  content: "\ea64"; }

/* all icon font classes list here */
.btn-twitter {
  color: #55acee !important; }
  .btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
    color: #55acee !important; }

.btn-facebook {
  color: #3b5998 !important; }
  .btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
    color: #3b5998 !important; }

.btn-google {
  color: #dd4b39 !important; }
  .btn-google:hover, .btn-google:focus, .btn-google:active {
    color: #dd4b39 !important; }

.btn-linkedin {
  color: #0976b4 !important; }
  .btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
    color: #0976b4 !important; }

.btn-twitter-bg {
  background-color: #55acee !important;
  border-color: #55acee !important; }
  .btn-twitter-bg:hover {
    background-color: #3ea1ec !important;
    border-color: #3ea1ec !important; }

.btn-facebook-bg {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important; }
  .btn-facebook-bg:hover {
    background-color: #344e86 !important;
    border-color: #344e86 !important; }

.btn-google-bg {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important; }
  .btn-google-bg:hover {
    background-color: #d73925 !important;
    border-color: #d73925 !important; }

.btn-github-bg {
  background-color: #767676 !important;
  border-color: #767676 !important; }
  .btn-github-bg:hover {
    background-color: dimgray !important;
    border-color: dimgray !important; }

/*             Navigation menu                */
/*             Navigation Tabs                 */
.nav-tabs-navigation {
  text-align: center;
  border-bottom: 1px solid #F1EAE0;
  margin-bottom: 30px; }
  .nav-tabs-navigation .nav > .nav-item > .nav-link {
    padding-bottom: 20px; }

.nav-tabs-wrapper {
  display: inline-block;
  margin-bottom: -6px;
  margin-left: 1.25%;
  margin-right: 1.25%;
  position: relative;
  width: auto; }

.nav-tabs {
  border-bottom: 0 none;
  font-size: 16px;
  font-weight: 600; }
  .nav-tabs .nav-item .nav-link {
    border: 0 none;
    color: #A49E93;
    background-color: transparent; }
  .nav-tabs .nav-item .nav-link:hover {
    color: #66615b; }
  .nav-tabs .nav-item .nav-link.active {
    color: #66615b; }
  .nav-tabs .nav-item {
    color: #66615b;
    position: relative; }
    .nav-tabs .nav-item .nav-link.active,
    .nav-tabs .nav-item .nav-link.active:hover,
    .nav-tabs .nav-item .nav-link.active:focus {
      background-color: transparent;
      border: 0 none; }
      .nav-tabs .nav-item .nav-link.active:after,
      .nav-tabs .nav-item .nav-link.active:hover:after,
      .nav-tabs .nav-item .nav-link.active:focus:after {
        border-bottom: 11px solid #FFFFFF;
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        right: 40%;
        bottom: 5px; }
      .nav-tabs .nav-item .nav-link.active:before,
      .nav-tabs .nav-item .nav-link.active:hover:before,
      .nav-tabs .nav-item .nav-link.active:focus:before {
        border-bottom: 11px solid #F1EAE0;
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        right: 40%;
        bottom: 6px; }
  .nav-tabs .nav-item.show .nav-link {
    background-color: transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -6px;
    margin-left: -46px;
    border-radius: 8px; }
    .nav-tabs .dropdown-menu .dropdown-item:hover,
    .nav-tabs .dropdown-menu .dropdown-item.active {
      color: #FFFFFF;
      background-color: #68B3C8; }
    .nav-tabs .dropdown-menu :before {
      border-bottom: 11px solid #F1EAE0;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -11px; }
    .nav-tabs .dropdown-menu :after {
      border-bottom: 11px solid #FFFCF5;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -10px; }

.profile-content .tab-content .tab-pane {
  min-height: 200px; }

.profile-content .tab-content #tweets, .profile-content .tab-content #connections, .profile-content .tab-content #media {
  height: 100%; }

/*             Navigation Pills               */
.nav-pills .nav-item + .nav-link {
  margin-left: 0; }

.nav-pills .nav-item .nav-link {
  border: 1px solid #66615B;
  border-radius: 0;
  color: #66615B;
  font-weight: 600;
  margin-left: -1px;
  padding: 10px 25px; }

.nav-pills .nav-item.active .nav-link,
.nav-pills .nav-item.active .nav-link:hover,
.nav-pills .nav-item.active .nav-link:focus {
  background-color: #66615B;
  color: #FFFFFF; }

.nav-pills .nav-item:first-child .nav-link {
  border-radius: 30px 0 0 30px !important;
  margin: 0; }

.nav-pills .nav-item:last-child .nav-link {
  border-radius: 0 30px 30px 0 !important; }

.nav-pills .nav-item .nav-link.active {
  background-color: #66615B;
  color: #FFFFFF; }

.nav-pills-primary .nav-item .nav-link {
  border: 1px solid #51cbce !important;
  color: #51cbce !important; }
  .nav-pills-primary .nav-item .nav-link.active {
    border: 1px solid #51cbce !important;
    color: #FFFFFF !important; }

.nav-pills-danger .nav-item .nav-link {
  border: 1px solid #f5593d !important;
  color: #f5593d !important; }
  .nav-pills-danger .nav-item .nav-link.active {
    border: 1px solid #f5593d !important;
    color: #FFFFFF !important; }

.nav-pills-info .nav-item .nav-link {
  border: 1px solid #51bcda !important;
  color: #51bcda !important; }
  .nav-pills-info .nav-item .nav-link.active {
    border: 1px solid #51bcda !important;
    color: #FFFFFF !important; }

.nav-pills-success .nav-item .nav-link {
  border: 1px solid #6bd098 !important;
  color: #6bd098 !important; }
  .nav-pills-success .nav-item .nav-link.active {
    border: 1px solid #6bd098 !important;
    color: #FFFFFF !important; }

.nav-pills-warning .nav-item .nav-link {
  border: 1px solid #fbc658 !important;
  color: #fbc658 !important; }
  .nav-pills-warning .nav-item .nav-link.active {
    border: 1px solid #fbc658 !important;
    color: #FFFFFF !important; }

.nav .nav-item .nav-link:hover,
.nav .nav-item .nav-link:focus {
  background-color: transparent; }

.navbar {
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15); }
  .navbar .navbar-brand {
    font-weight: 600;
    margin: 5px 0px;
    padding: 20px 15px;
    font-size: 14px;
    color: #66615B;
    text-transform: uppercase; }
  .navbar .navbar-toggler:focus {
    outline: none; }
  .navbar .form-control-feedback {
    padding-left: 0px; }
  .navbar:not([class*="bg"]) .navbar-toggler .navbar-toggler-bar {
    background: #66615B !important; }
  .navbar .navbar-nav .nav-item .nav-link {
    line-height: 1.6;
    margin: 15px 3px;
    padding: 10px 15px;
    opacity: .8;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #66615B; }
    .navbar .navbar-nav .nav-item .nav-link p {
      margin: 0px 0px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5em;
      padding: 15px 0; }
  .navbar .navbar-nav .nav-item .nav-link.btn {
    margin: 15px 3px;
    padding: 9px; }
  .navbar .navbar-nav .nav-item .nav-link [class^="fa"] {
    font-size: 17px;
    position: relative;
    right: 5px; }
  .navbar .navbar-nav .dropdown-menu {
    border-radius: 12px;
    margin-top: 1px; }
  .navbar .navbar-nav .nav-item .btn i {
    color: #FFFFFF; }
  .navbar .navbar-collapse .nav-item .nav-link p {
    display: inline; }
  .navbar .navbar-collapse .nav-item .dropdown-item i {
    margin: 0 10px;
    margin: 0 10px 0px 5px;
    font-size: 18px;
    position: relative;
    top: 3px; }
  .navbar .navbar-collapse.show .navbar-nav .nav-item {
    padding-right: 10px; }
  .navbar .navbar-collapse:after {
    background-color: #FFFCF5; }
  .navbar #navbarSupportedContent .nav-item {
    position: relative; }
  .navbar .notification-bubble {
    padding: 0.4em 0.6em;
    position: absolute;
    top: 10px;
    right: -2px; }
  .navbar .btn {
    margin: 14px 3px;
    font-size: 12px; }
    .navbar .btn i {
      font-size: 14px;
      position: relative;
      top: 2px; }
  .navbar .btn-simple {
    font-size: 16px; }
  .navbar .caret {
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    position: absolute; }
  .navbar.navbar-transparent {
    background: transparent !important;
    padding-top: 25px;
    box-shadow: none; }
    .navbar.navbar-transparent .navbar-brand {
      color: #FFFFFF; }
    .navbar.navbar-transparent .navbar-nav .nav-item .nav-link {
      color: #FFFFFF; }
    .navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar {
      background: #FFFFFF !important; }
  .navbar .logo-container {
    margin-top: 5px; }
    .navbar .logo-container .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 50px;
      float: left; }
      .navbar .logo-container .logo img {
        width: 100%; }
    .navbar .logo-container .brand {
      font-size: 18px;
      color: #FFFFFF;
      line-height: 20px;
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      width: 75px;
      height: 50px; }
  .navbar .navbar-toggler .navbar-toggler-bar + .navbar-toggler-bar,
  .navbar .navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
    margin-top: 4px; }
  .navbar .navbar-toggler .navbar-toggler-bar {
    background: #FFFFFF !important;
    display: block;
    position: relative;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }

.navbar-transparent .navbar-brand, [class*="bg"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }
  .navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="bg"] .navbar-brand:focus, [class*="bg"] .navbar-brand:hover {
    background-color: transparent;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #FFFFFF; }

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF; }

.navbar-transparent .navbar-nav .active .nav-link
.active .nav-link:hover,
.navbar-transparent .navbar-nav .active .nav-link:focus,
.navbar-transparent .navbar-nav .nav-item .nav-link:hover,
.navbar-transparent .navbar-nav .nav-item .nav-link:focus, [class*="bg"] .navbar-nav .active .nav-link
.active .nav-link:hover,
[class*="bg"] .navbar-nav .active .nav-link:focus,
[class*="bg"] .navbar-nav .nav-item .nav-link:hover,
[class*="bg"] .navbar-nav .nav-item .nav-link:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav .nav .nav-item a.btn:hover, [class*="bg"] .navbar-nav .nav .nav-item a.btn:hover {
  background-color: transparent; }

.navbar-transparent .navbar-nav .dropdown .nav-link .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:focus .caret, [class*="bg"] .navbar-nav .dropdown .nav-link .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:hover .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-transparent .navbar-nav .open .nav-link,
.navbar-transparent .navbar-nav .open .nav-link:hover,
.navbar-transparent .navbar-nav .open .nav-link:focus, [class*="bg"] .navbar-nav .open .nav-link,
[class*="bg"] .navbar-nav .open .nav-link:hover,
[class*="bg"] .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .btn-default.btn-fill, [class*="bg"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*="bg"] .btn-default.btn-fill:hover,
[class*="bg"] .btn-default.btn-fill:focus,
[class*="bg"] .btn-default.btn-fill:active,
[class*="bg"] .btn-default.btn-fill.active,
[class*="bg"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029; }

.bd-docs .navigation-example .navbar.navbar-transparent {
  padding-top: 0;
  padding: 20px 0;
  margin-top: 20px; }

.dropdown > .dropdown-menu:first-of-type {
  display: block;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s; }

.dropdown .dropdown-menu {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  visibility: hidden;
  position: absolute; }
  .dropdown .dropdown-menu[x-placement="top-start"] {
    -webkit-transform: translate3d(0, 0px, 0) !important;
    -moz-transform: translate3d(0, 0px, 0) !important;
    -o-transform: translate3d(0, 0px, 0) !important;
    -ms-transform: translate3d(0, 0px, 0) !important;
    transform: translate3d(0, 0px, 0) !important; }
  .dropdown .dropdown-menu[x-placement="bottom-start"] {
    -webkit-transform: translate3d(0, 0px, 0) !important;
    -moz-transform: translate3d(0, 0px, 0) !important;
    -o-transform: translate3d(0, 0px, 0) !important;
    -ms-transform: translate3d(0, 0px, 0) !important;
    transform: translate3d(0, 0px, 0) !important; }

.dropdown.show .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible; }
  .dropdown.show .dropdown-menu[x-placement="top-start"] {
    -webkit-transform: translate3d(0, -50px, 0) !important;
    -moz-transform: translate3d(0, -50px, 0) !important;
    -o-transform: translate3d(0, -50px, 0) !important;
    -ms-transform: translate3d(0, -50px, 0) !important;
    transform: translate3d(0, -50px, 0) !important;
    top: auto !important;
    bottom: 0 !important; }
  .dropdown.show .dropdown-menu[x-placement="bottom-start"] {
    -webkit-transform: translate3d(0, 40px, 0) !important;
    -moz-transform: translate3d(0, 40px, 0) !important;
    -o-transform: translate3d(0, 40px, 0) !important;
    -ms-transform: translate3d(0, 40px, 0) !important;
    transform: translate3d(0, 40px, 0) !important;
    bottom: auto !important;
    top: 0 !important; }

.dropdown-menu {
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 12px;
  margin-top: 10px;
  padding: 0px; }
  .dropdown-menu .divider {
    background-color: #F1EAE0;
    margin: 0px; }
  .dropdown-menu .dropdown-header {
    color: #9A9A9A;
    font-size: 12px;
    padding: 10px 15px; }
  .dropdown-menu .no-notification {
    color: #9A9A9A;
    font-size: 1.2em;
    padding: 30px 30px;
    text-align: center; }
  .dropdown-menu .dropdown-item {
    color: #66615b;
    font-size: 14px;
    padding: 10px 45px 10px 15px;
    clear: both;
    white-space: nowrap;
    width: 100%;
    display: block; }
    .dropdown-menu .dropdown-item img {
      margin-top: -3px; }
  .dropdown-menu .dropdown-item:focus {
    outline: 0 !important; }
  .btn-group.select .dropdown-menu {
    min-width: 100%; }
  .dropdown-menu .dropdown-item:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px; }
  .dropdown-menu .dropdown-item:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; }
  .dropdown-menu .dropdown-item.dropdown-toggle:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; }
    .dropdown-menu .dropdown-item.dropdown-toggle:last-of-type + .dropdown-menu.show {
      left: 100% !important; }
    .dropdown-menu .dropdown-item.dropdown-toggle:last-of-type + .dropdown-menu .dropdown-item.dropdown-toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .dropdown-menu .dropdown-item.dropdown-toggle:last-of-type + .dropdown-menu li .dropdown-item.dropdown-toggle {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px; }
  .select .dropdown-menu .dropdown-item:first-child {
    border-radius: 0;
    border-bottom: 0 none; }
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus {
    color: #FFFFFF !important;
    opacity: 1;
    text-decoration: none; }
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus {
    background-color: #66615B; }
  .dropdown-menu.dropdown-primary .dropdown-item:hover,
  .dropdown-menu.dropdown-primary .dropdown-item:focus {
    background-color: #6dd3d6; }
  .dropdown-menu.dropdown-info .dropdown-item:hover,
  .dropdown-menu.dropdown-info .dropdown-item:focus {
    background-color: #6ec7e0; }
  .dropdown-menu.dropdown-success .dropdown-item:hover,
  .dropdown-menu.dropdown-success .dropdown-item:focus {
    background-color: #86d9ab; }
  .dropdown-menu.dropdown-warning .dropdown-item:hover,
  .dropdown-menu.dropdown-warning .dropdown-item:focus {
    background-color: #fcd27b; }
  .dropdown-menu.dropdown-danger .dropdown-item:hover,
  .dropdown-menu.dropdown-danger .dropdown-item:focus {
    background-color: #f7765f; }

.dropdown-divider {
  margin: 0 !important; }

.btn-group.select.open {
  overflow: visible; }

.dropdown-menu-right {
  right: -2px;
  left: auto; }

.alert {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding: 10px 15px;
  font-size: 14px; }
  .alert .close {
    font-size: 20px;
    color: #FFFFFF; }
  .container .alert {
    border-radius: 4px; }
  .navbar .alert {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3; }
  .navbar:not(.navbar-transparent) .alert {
    top: 70px; }
  .alert .alert-icon {
    display: block;
    font-size: 30px;
    left: 15px;
    position: absolute;
    top: 50%;
    margin-top: -22px; }
  .alert .alert-wrapper.message {
    padding-right: 60px; }
  .alert .alert-wrapper i {
    position: relative;
    font-size: 20px;
    top: 5px;
    margin-top: -5px;
    font-weight: 600; }

.alert-info {
  background-color: #6ec7e0; }

.alert-success {
  background-color: #86d9ab; }

.alert-warning {
  background-color: #fcd27b; }

.alert-danger {
  background-color: #f7765f; }

.img-rounded {
  border-radius: 12px;
  transition: opacity 0.5s ease 0s;
  max-width: 100%; }

.img-details {
  min-height: 50px;
  padding: 0 4px 0.5em; }

.img-details img {
  width: 50px; }

.img-details .author {
  margin-left: 10px;
  margin-top: -21px;
  width: 40px; }

.img-circle {
  background-color: #FFFFFF;
  margin-bottom: 10px;
  padding: 4px;
  border-radius: 50% !important; }

.img-thumbnail {
  border: 0 none;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(164, 158, 147, 0.6);
  margin-bottom: 10px; }

.img-no-padding {
  padding: 0px; }

.example-page .img-rounded {
  margin: 50px 0 20px; }

.img-shadow {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.images-title {
  margin-bottom: 20px;
  height: 50px; }

.nav-link .profile-photo-small {
  width: 40px;
  height: 30px;
  margin: -10px 0 0 -15px; }

.profile-picture {
  margin: 0 auto; }
  .profile-picture .fileinput-new img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px; }
  .profile-picture .fileinput-exists img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    margin-bottom: 10px; }

.tooltip {
  font-size: 12px;
  font-weight: 400; }

.tooltip-inner {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.14), 0 0 0 1px rgba(115, 71, 38, 0.23);
  color: #66615B;
  max-width: 200px;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none; }

.tooltip-inner:after {
  content: "";
  display: inline-block;
  position: absolute; }

.tooltip-inner:before {
  content: "";
  display: inline-block;
  position: absolute; }

.tooltip.fade.bs-tooltip-left,
.tooltip.fade.bs-tooltip-left.show {
  left: -5px !important; }
  .tooltip.fade.bs-tooltip-left .arrow,
  .tooltip.fade.bs-tooltip-left.show .arrow {
    border-left: 11px solid rgba(0, 0, 0, 0.2);
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    right: -6px;
    left: auto;
    margin-left: 0;
    top: -8px; }
    .tooltip.fade.bs-tooltip-left .arrow::before,
    .tooltip.fade.bs-tooltip-left.show .arrow::before {
      border-left: 11px solid #FFFFFF;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      right: 1px;
      left: auto;
      margin-left: 0;
      top: -8px; }

.tooltip.fade.bs-tooltip-right,
.tooltip.fade.bs-tooltip-right.show {
  left: 6px !important; }
  .tooltip.fade.bs-tooltip-right .arrow,
  .tooltip.fade.bs-tooltip-right.show .arrow {
    border-right: 11px solid rgba(0, 0, 0, 0.2);
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    left: -6px;
    right: auto;
    margin-right: 0;
    top: -8px; }
    .tooltip.fade.bs-tooltip-right .arrow::before,
    .tooltip.fade.bs-tooltip-right.show .arrow::before {
      border-right: 11px solid #FFFFFF;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      left: 1px;
      right: auto;
      margin-right: 0;
      top: -8px; }

.tooltip.fade.bs-tooltip-top,
.tooltip.fade.bs-tooltip-top.show {
  top: -6px !important; }
  .tooltip.fade.bs-tooltip-top .arrow,
  .tooltip.fade.bs-tooltip-top.show .arrow {
    border-top: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    right: auto;
    margin-right: 0;
    top: 43px; }
    .tooltip.fade.bs-tooltip-top .arrow::before,
    .tooltip.fade.bs-tooltip-top.show .arrow::before {
      border-top: 11px solid #FFFFFF;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      left: -8px;
      right: auto;
      top: -12px; }

.tooltip.fade.bs-tooltip-bottom,
.tooltip.fade.bs-tooltip-bottom.show {
  top: 6px !important; }
  .tooltip.fade.bs-tooltip-bottom .arrow,
  .tooltip.fade.bs-tooltip-bottom.show .arrow {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    right: auto;
    margin-right: 0;
    top: -6px; }
    .tooltip.fade.bs-tooltip-bottom .arrow::before,
    .tooltip.fade.bs-tooltip-bottom.show .arrow::before {
      border-bottom: 11px solid #FFFFFF;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      left: -8px;
      right: auto;
      top: 1px; }

.tooltip.show {
  opacity: 1 !important; }

.popover {
  border: 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #66615b;
  font-weight: 400;
  padding: 0;
  z-index: 1031;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center; }

.popover-header {
  background-color: #FFFCF5;
  border-bottom: 0 none;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  padding: 15px 15px 0px 15px;
  margin: 0;
  color: #66615b;
  text-align: center;
  border-radius: 4px 4px 0 0;
  margin-bottom: -10px; }

.popover-header {
  background-color: #FFFFFF !important;
  text-align: center; }

.popover-body {
  padding: 15px;
  text-align: center; }

.popover .arrow {
  border: 0; }

.popover.top .arrow {
  margin-left: 0; }

.popover.bottom .arrow:after {
  border-bottom-color: #f7765f; }

.popover-filter {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: #000000;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  transition: visibility 0s linear 0.3s,opacity 0.3s linear; }

.popover-filter.in {
  visibility: visible;
  opacity: 0.2;
  filter: alpha(opacity=20);
  transition-delay: 0s; }

.popover.left > .arrow::after {
  border-left-color: #f7765f;
  bottom: -20px; }

.popover.top > .arrow::after {
  border-top-color: #f7765f; }

.popover.right > .arrow::after {
  border-right-color: #f7765f; }

.popover.left::before {
  border-left-color: #f7765f;
  bottom: -20px; }

.popover-filter.in {
  visibility: visible;
  opacity: 0.2;
  filter: alpha(opacity=20);
  transition-delay: 0s; }

.popover-primary {
  background-color: #6dd3d6;
  color: #FFFFFF; }
  .popover-primary .popover-title {
    background-color: #6dd3d6;
    color: rgba(0, 0, 0, 0.56); }
  .popover-primary.bottom .arrow:after {
    border-bottom-color: #6dd3d6; }
  .popover-primary.left > .arrow::after {
    border-left-color: #6dd3d6; }
  .popover-primary.top > .arrow::after {
    border-top-color: #6dd3d6; }
  .popover-primary.right > .arrow::after {
    border-right-color: #6dd3d6; }

.popover-info {
  background-color: #6ec7e0;
  color: #FFFFFF; }
  .popover-info .popover-title {
    background-color: #6ec7e0;
    color: rgba(0, 0, 0, 0.56); }
  .popover-info.bottom .arrow:after {
    border-bottom-color: #6ec7e0; }
  .popover-info.left > .arrow::after {
    border-left-color: #6ec7e0; }
  .popover-info.top > .arrow::after {
    border-top-color: #6ec7e0; }
  .popover-info.right > .arrow::after {
    border-right-color: #6ec7e0; }

.popover-success {
  background-color: #86d9ab;
  color: #FFFFFF; }
  .popover-success .popover-title {
    background-color: #86d9ab;
    color: rgba(0, 0, 0, 0.56); }
  .popover-success.bottom .arrow:after {
    border-bottom-color: #86d9ab; }
  .popover-success.left > .arrow::after {
    border-left-color: #86d9ab; }
  .popover-success.top > .arrow::after {
    border-top-color: #86d9ab; }
  .popover-success.right > .arrow::after {
    border-right-color: #86d9ab; }

.popover-warning {
  background-color: #fcd27b;
  color: #FFFFFF; }
  .popover-warning .popover-title {
    background-color: #fcd27b;
    color: rgba(0, 0, 0, 0.56); }
  .popover-warning.bottom .arrow:after {
    border-bottom-color: #fcd27b; }
  .popover-warning.left > .arrow::after {
    border-left-color: #fcd27b; }
  .popover-warning.top > .arrow::after {
    border-top-color: #fcd27b; }
  .popover-warning.right > .arrow::after {
    border-right-color: #fcd27b; }

.popover-danger {
  background-color: #f7765f;
  color: #FFFFFF; }
  .popover-danger .popover-title {
    background-color: #f7765f;
    color: rgba(0, 0, 0, 0.56); }
  .popover-danger.bottom .arrow:after {
    border-bottom-color: #f7765f; }
  .popover-danger.left > .arrow::after {
    border-left-color: #f7765f; }
  .popover-danger.top > .arrow::after {
    border-top-color: #f7765f; }
  .popover-danger.right > .arrow::after {
    border-right-color: #f7765f; }

.popover-primary.bs-tether-element-attached-bottom::after,
.popover-primary.bs-tether-element-attached-bottom::before,
.popover-primary.popover-top::after,
.popover-primary.popover-top::before {
  border-top-color: #6dd3d6 !important; }

.popover.popover-info.bs-tether-element-attached-top::after,
.popover.popover-info.popover-bottom::after,
.popover.popover-info.bs-tether-element-attached-top::before,
.popover.popover-info.popover-bottom::before {
  border-bottom-color: #6ec7e0 !important; }

.popover-success.bs-tether-element-attached-left::after,
.popover-success.bs-tether-element-attached-left::before,
.popover-success.popover-right::after,
.popover-success.popover-right::before {
  border-right-color: #86d9ab !important; }

.popover.popover-warning.bs-tether-element-attached-right::after,
.popover.popover-warning.bs-tether-element-attached-right::before,
.popover.popover-warning.popover-left::after,
.popover.popover-warning.popover-left::before {
  border-left-color: #fcd27b !important; }

.popover.popover-danger.bs-tether-element-attached-right::after,
.popover.popover-danger.bs-tether-element-attached-right::before,
.popover.popover-danger.popover-left::after,
.popover.popover-danger.popover-left::before {
  border-left-color: #f7765f !important; }

.popover.bs-tether-element-attached-top::after, .popover.popover-bottom::after,
.popover.bs-tether-element-attached-top::before, .popover.popover-bottom::before {
  border-bottom-color: #fff !important; }

.modal-header {
  border-bottom: 1px solid #DDDDDD;
  padding: 20px;
  text-align: center;
  display: block !important; }
  .modal-header.no-border-header {
    border-bottom: 0 none !important; }
    .modal-header.no-border-header .modal-title {
      margin-top: 20px; }
  .modal-header button.close:focus {
    outline: none; }

.modal-dialog.modal-sm .modal-header button.close, .modal-dialog.modal-register .modal-header button.close {
  margin-top: 0; }

.modal-content {
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1); }
  .modal-content .modal-header h6 {
    margin-top: 10px; }

.modal-dialog {
  padding-top: 60px; }

.modal-body {
  padding: 20px 50px;
  color: #000; }

.modal-footer {
  border-top: 1px solid #DDDDDD;
  padding: 0px; }
  .modal-footer.no-border-footer {
    border-top: 0 none; }

.modal-footer .left-side, .modal-footer .right-side {
  display: inline-block;
  text-align: center;
  width: 49%; }

.modal-footer .btn-link {
  padding: 20px;
  width: 100%; }

.modal-footer .divider {
  background-color: #DDDDDD;
  display: inline-block;
  float: inherit;
  height: 63px;
  margin: 0px -3px;
  width: 1px; }

.modal-register .modal-footer {
  text-align: center;
  margin-bottom: 25px;
  padding: 20px 0 15px; }
  .modal-register .modal-footer span {
    width: 100%; }

.modal-header:after {
  display: table;
  content: " "; }

.modal-header:before {
  display: table;
  content: " "; }

.section-nucleo-icons {
  padding: 100px 0; }
  .section-nucleo-icons .icons-container {
    position: relative;
    max-width: 450px;
    height: 300px;
    max-height: 300px;
    margin: 0 auto; }
    .section-nucleo-icons .icons-container i {
      font-size: 34px;
      position: absolute;
      top: 0;
      left: 0; }
      .section-nucleo-icons .icons-container i:nth-child(1) {
        top: 5%;
        left: 7%; }
      .section-nucleo-icons .icons-container i:nth-child(2) {
        top: 28%;
        left: 24%; }
      .section-nucleo-icons .icons-container i:nth-child(3) {
        top: 40%; }
      .section-nucleo-icons .icons-container i:nth-child(4) {
        top: 18%;
        left: 62%; }
      .section-nucleo-icons .icons-container i:nth-child(5) {
        top: 74%;
        left: 3%; }
      .section-nucleo-icons .icons-container i:nth-child(6) {
        top: 36%;
        left: 44%;
        font-size: 65px;
        color: #f5593d;
        padding: 1px; }
      .section-nucleo-icons .icons-container i:nth-child(7) {
        top: 59%;
        left: 26%; }
      .section-nucleo-icons .icons-container i:nth-child(8) {
        top: 60%;
        left: 69%; }
      .section-nucleo-icons .icons-container i:nth-child(9) {
        top: 72%;
        left: 47%; }
      .section-nucleo-icons .icons-container i:nth-child(10) {
        top: 88%;
        left: 27%; }
      .section-nucleo-icons .icons-container i:nth-child(11) {
        top: 31%;
        left: 80%; }
      .section-nucleo-icons .icons-container i:nth-child(12) {
        top: 88%;
        left: 68%; }
      .section-nucleo-icons .icons-container i:nth-child(13) {
        top: 5%;
        left: 81%; }
      .section-nucleo-icons .icons-container i:nth-child(14) {
        top: 58%;
        left: 90%; }
      .section-nucleo-icons .icons-container i:nth-child(15) {
        top: 6%;
        left: 40%; }

.section-dark .icons-container {
  color: #FFFFFF; }

.info .icon {
  margin-top: 0;
  font-size: 3.4em; }

.icon-primary {
  color: #51cbce; }

.icon-info {
  color: #51bcda; }

.icon-success {
  color: #6bd098; }

.icon-warning {
  color: #fbc658; }

.icon-danger {
  color: #f5593d; }

.icon-neutral {
  color: #FFFFFF; }

.carousel-control {
  background-color: #f5593d;
  border-radius: 50%;
  opacity: 1;
  text-shadow: none; }
  .carousel-control:hover, .carousel-control:focus {
    opacity: 1;
    background-color: #f33816; }

.carousel-control.left {
  height: 30px;
  top: 50%;
  width: 30px;
  left: 25px;
  opacity: 0; }

.carousel-control.right {
  height: 30px;
  right: 25px;
  top: 50%;
  width: 30px;
  opacity: 0; }

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
  display: inline-block;
  z-index: 5; }

.carousel-control .fa {
  font-size: 26px;
  margin: 2px; }

.carousel-control.left, .carousel-control.right {
  background-image: none; }

.page-carousel {
  border-radius: 12px !important;
  border: none !important;
  width: 100%; }

.carousel-inner > .carousel-item > img,
.carousel-inner > .carousel-item > a > img {
  border-radius: 12px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5); }

.carousel-inner .carousel-item img {
  width: 100%; }

.carousel-indicators > li {
  background-color: rgba(204, 204, 204, 0.49);
  border: 0 none; }

.carousel-indicators .active {
  background-color: #f5593d; }

.page-carousel:hover .carousel-control.right,
.page-carousel:hover .carousel-control.left {
  opacity: 1;
  z-index: 1; }

.card .carousel .carousel-indicators {
  bottom: 0; }

.card .carousel a {
  position: absolute;
  color: #FFFFFF !important; }
  .card .carousel a:hover {
    color: #FFFFFF !important; }
  .card .carousel a .fa-angle-left:before {
    padding-right: 3px; }
  .card .carousel a .fa-angle-right:before {
    padding-left: 3px; }

.footer {
  background-attachment: fixed;
  position: relative;
  line-height: 20px; }
  .footer .footer-nav ul {
    padding-left: 0;
    margin-bottom: 0; }

.footer nav > ul {
  list-style: none;
  font-weight: normal; }

.footer nav > ul > li {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center; }

.footer nav > ul a:not(.btn) {
  color: #777777;
  display: block;
  margin-bottom: 3px;
  line-height: 1.6;
  opacity: .8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):focus {
  color: #777777;
  opacity: 1; }

.footer .copyright {
  color: #777777;
  padding: 10px 0px;
  font-size: 14px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center; }

.footer .heart {
  color: #EB5E28; }

.footer {
  background-color: #FFFFFF;
  line-height: 36px; }
  .footer.footer-black h4 {
    color: #FFFFFF; }
  .footer .links {
    display: inline-block; }
    .footer .links ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: 600; }
      .footer .links ul > li {
        display: inline-block;
        padding-right: 20px; }
      .footer .links ul > li:last-child {
        padding-right: 0px; }
      .footer .links ul a:not(.btn) {
        color: #66615b;
        display: block;
        font-size: 0.9em;
        margin-bottom: 3px; }
        .footer .links ul a:not(.btn):hover, .footer .links ul a:not(.btn):focus {
          color: #403D39; }
      .footer .links ul.uppercase-links {
        text-transform: uppercase; }
      .footer .links ul.stacked-links {
        margin-top: 10px; }
        .footer .links ul.stacked-links > li {
          display: block;
          line-height: 26px; }
        .footer .links ul.stacked-links h4 {
          margin-top: 0px; }
  .footer .social-area {
    padding: 15px 0; }
    .footer .social-area .btn {
      margin-bottom: 3px;
      padding: 8px; }
      .footer .social-area .btn i {
        margin-right: 0 !important;
        color: #fff; }
  .footer hr {
    border-color: #DDDDDD;
    border-width: 1px 0 0;
    margin-top: 5px;
    margin-bottom: 5px; }
  .footer .copyright {
    color: #A49E9E;
    font-size: 0.9em; }
    .footer .copyright ul > li {
      padding-right: 0px; }
  .footer .title {
    color: #403D39; }

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: #333333;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease; }
  .card:not(.card-plain):hover {
    box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -moz-transform: translateY(-10px); }
  .card.no-transition:hover, .card.page-carousel:hover, .card.card-refine:hover, .card.card-register:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none; }
  .card a:not(.btn) {
    color: #444444; }
    .card a:not(.btn):hover, .card a:not(.btn):focus {
      color: #333333; }
  .card .card-description {
    color: #9A9A9A;
    margin-bottom: 5px;
    margin-top: 15px; }
  .card img {
    max-width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0; }
  .card[data-radius="none"] {
    border-radius: 0px; }
    .card[data-radius="none"] .header {
      border-radius: 0px 0px 0 0; }
      .card[data-radius="none"] .header img {
        border-radius: 0px 0px 0 0; }
  .card.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0; }
    .card.card-plain .card-body {
      padding-left: 5px;
      padding-right: 5px; }
    .card.card-plain img {
      border-radius: 12px; }
  .card .card-link {
    color: #FFFFFF; }
  .card .card-background-image ul li {
    color: #FFF;
    border-color: rgba(255, 255, 255, 0.3); }
  .card.card-blog,
  .card.card-testimonial .card-description + .card-title {
    margin-top: 30px; }
    .card.card-blog .card-image .img,
    .card.card-testimonial .card-description + .card-title .card-image .img {
      border-radius: 12px; }
    .card.card-blog .card-image .card-title,
    .card.card-testimonial .card-description + .card-title .card-image .card-title {
      text-align: center; }
  .card.card:not(.card-plain) .card-image .img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }
  .card.card-blog {
    margin-top: 5px; }
    .card.card-blog .btn {
      margin: 0 auto; }
  .card .author {
    display: inline-block; }
    .card .author .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px; }
    .card .author .avatar-big {
      width: 100px;
      height: 100px;
      border-radius: 50% !important;
      position: relative;
      margin-bottom: 15px; }
      .card .author .avatar-big.border-white {
        border: 4px solid #FFFFFF; }
      .card .author .avatar-big.border-gray {
        border: 4px solid #ccc; }
  .card .clearfix + .author {
    margin-top: 15px; }
  .card .stats {
    display: inline-block;
    float: right;
    color: #9A9A9A;
    font-size: 12px;
    margin-top: 6px; }
    .card .stats i {
      font-size: 16px;
      position: relative;
      text-align: center;
      top: 1px;
      margin-left: 5px;
      margin-top: -1px;
      opacity: .6; }
  .card .card-category {
    color: #9A9A9A; }
  .card .category-social .fa {
    font-size: 24px;
    position: relative;
    margin-top: -4px;
    top: 2px;
    margin-right: 5px; }
  .card .card-title-uppercase {
    text-transform: uppercase; }
  .card .header {
    position: relative;
    border-radius: 3px 3px 0 0;
    z-index: 3; }
    .card .header.header-with-icon {
      height: 150px; }
    .card .header img {
      width: 100%; }
    .card .header .card-category {
      padding: 15px; }
  .card .card-body .card-footer {
    padding: 0; }
  .card .card-body .card-description + .card-footer {
    padding-top: 10px; }
  .card .more {
    float: right;
    z-index: 4;
    display: block;
    padding-top: 10px;
    padding-right: 10px;
    position: relative; }
  .card .filter,
  .card .header .actions {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.76);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0; }
  .card .header .actions {
    background-color: transparent;
    z-index: 3; }
    .card .header .actions .btn {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  .card:hover .filter {
    opacity: .7; }
  .card:hover .header .social-line,
  .card:hover .header .actions {
    opacity: 1; }
  .card .card-category i,
  .card .label i {
    font-size: 15px; }
  .card > .card-title {
    margin: 0;
    padding: 30px 0 0; }
  .card .card-body {
    padding: 20px; }
    .card .card-body .card-title {
      margin: 5px 0 0 0; }
    .card .card-body .card-category ~ .card-title {
      margin-top: 5px; }
  .card .card-text {
    font-size: 15px;
    color: #66615b;
    padding-bottom: 15px; }
  .card h6 {
    font-size: 12px;
    margin: 0; }
  .card .card-footer {
    padding: 15px;
    background: transparent;
    border-top: 0 none; }
    .card .card-footer .social-line .btn:first-child {
      border-radius: 0 0 0 6px; }
    .card .card-footer .social-line .btn:last-child {
      border-radius: 0 0 6px 0; }
  .card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #DDDDDD;
    content: "";
    position: absolute; }
  .card .card-icon {
    display: block;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding: 30px 0 10px; }
    .card .card-icon i {
      font-size: 40px;
      width: 105px;
      border: 2px solid #ccc;
      border-radius: 50%;
      height: 105px;
      line-height: 105px; }
  .col-lg-4 .card .card-icon i {
    font-size: 80px;
    padding: 22px; }
  .card.card-with-border .card-body {
    padding: 15px 15px 25px 15px; }
  .card.card-with-border .card-footer {
    padding-bottom: 25px; }
  .card.card-with-border:after {
    position: absolute;
    display: block;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    content: "";
    top: 5px;
    left: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 5px; }
  .card.card-just-text .card-body {
    padding: 50px 65px;
    text-align: center; }
  .card.card-just-text #dLabel {
    float: right; }
    .card.card-just-text #dLabel i {
      font-size: 20px; }
  .card.card-just-text .dropdown-menu {
    margin-top: 55px; }
  .card[data-background="image"] {
    background-position: center center;
    background-size: cover;
    text-align: center; }
    .card[data-background="image"] .card-body {
      position: relative;
      z-index: 2;
      min-height: 280px;
      padding-top: 40px;
      padding-bottom: 40px;
      max-width: 440px;
      margin: 0 auto;
      text-align: center; }
    .card[data-background="image"] .card-title {
      color: #FFF;
      margin-top: 10px; }
    .card[data-background="image"]:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.56);
      border-radius: 12px; }
    .card[data-background="image"] .card-category {
      color: #FFFFFF; }
  .card[data-background="image"] .image, .card[data-background="color"] .image, .card[data-color] .image {
    border-radius: 6px; }
  .card[data-background="image"] .filter, .card[data-background="color"] .filter, .card[data-color] .filter {
    border-radius: 6px; }
  .card[data-background="image"] .card-description, .card[data-background="color"] .card-description, .card[data-color] .card-description {
    color: rgba(255, 255, 255, 0.8); }
  .card[data-background="image"] .card-title,
  .card[data-background="image"] .card-category,
  .card[data-background="image"] .card-text,
  .card[data-background="image"] .card-body,
  .card[data-background="image"] .card-footer,
  .card[data-background="image"] .stats,
  .card[data-background="image"] small,
  .card[data-background="image"] [class*="text-"],
  .card[data-background="image"] ul li b,
  .card[data-background="image"] a:not(.btn), .card[data-background="color"] .card-title,
  .card[data-background="color"] .card-category,
  .card[data-background="color"] .card-text,
  .card[data-background="color"] .card-body,
  .card[data-background="color"] .card-footer,
  .card[data-background="color"] .stats,
  .card[data-background="color"] small,
  .card[data-background="color"] [class*="text-"],
  .card[data-background="color"] ul li b,
  .card[data-background="color"] a:not(.btn), .card[data-color] .card-title,
  .card[data-color] .card-category,
  .card[data-color] .card-text,
  .card[data-color] .card-body,
  .card[data-color] .card-footer,
  .card[data-color] .stats,
  .card[data-color] small,
  .card[data-color] [class*="text-"],
  .card[data-color] ul li b,
  .card[data-color] a:not(.btn) {
    color: #FFFFFF !important; }
  .card[data-background="image"] a:not(.btn):hover,
  .card[data-background="image"] a:not(.btn):focus, .card[data-background="color"] a:not(.btn):hover,
  .card[data-background="color"] a:not(.btn):focus, .card[data-color] a:not(.btn):hover,
  .card[data-color] a:not(.btn):focus {
    color: #FFFFFF; }
  .card[data-background="image"] .card-icon i, .card[data-background="color"] .card-icon i, .card[data-color] .card-icon i {
    color: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.3); }
  .card[data-background="image"].card-with-border:after, .card[data-background="color"].card-with-border:after, .card[data-color].card-with-border:after {
    border: 1px solid rgba(255, 255, 255, 0.45); }
  .card[data-background="image"] .filter {
    opacity: 0.55;
    filter: alpha(opacity=55); }
  .card[data-background="image"]:hover .filter {
    opacity: 0.75;
    filter: alpha(opacity=75); }
  .card[data-color="blue"] {
    background: #6ec7e0; }
  .card[data-color="green"] {
    background: #86d9ab; }
  .card[data-color="yellow"] {
    background: #fcd27b; }
  .card[data-color="brown"] {
    background: #e4c4a1; }
  .card[data-color="purple"] {
    background: #cc90cc; }
  .card[data-color="orange"] {
    background: #f7765f; }

.btn-center {
  text-align: center; }

.card-big-shadow {
  max-width: 320px;
  position: relative; }
  .card-big-shadow:before {
    background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }

.card-image.card-big-shadow {
  max-width: 100%; }

.card-image .card {
  background-size: cover;
  min-height: 430px;
  width: 100%; }

.card-image .img-raised {
  margin-top: -20px; }

.page-header .card-pricing.card-plain .card-category,
.page-header .card-pricing.card-plain .card-title {
  color: #FFFFFF; }

.card-plain .card-avatar {
  margin-top: 0; }

.card-refine .panel-title {
  font-size: 1em; }

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }
  .card-plain .card-body {
    padding-left: 5px;
    padding-right: 5px; }
  .card-plain img {
    border-radius: 12px; }

.card-register {
  background-color: #FF8F5E;
  border-radius: 8px;
  color: #fff;
  max-width: 350px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px; }
  .card-register label {
    margin-top: 15px; }
  .card-register .card-title {
    color: #B33C12;
    text-align: center; }
  .card-register .btn {
    margin-top: 30px; }
  .card-register .forgot {
    text-align: center; }
  .card-register .input-group-text {
    border: 1px solid #FFFFFF; }

.card-profile {
  margin-top: 30px;
  text-align: center; }
  .card-profile .card-cover {
    height: 130px;
    background-position: center center;
    background-size: cover;
    border-radius: 12px 12px 0 0; }
  .card-profile .card-body .card-title {
    margin-top: 5px !important; }
  .card-profile .card-body .card-category {
    margin-bottom: 5px;
    margin-top: 5px; }
  .card-profile .card-avatar {
    max-width: 120px;
    max-height: 120px;
    margin: -60px auto 0;
    border-radius: 50%;
    overflow: hidden; }
    .card-profile .card-avatar.border-white {
      border: 4px solid #FFFFFF; }
    .card-profile .card-avatar.border-gray {
      border: 4px solid #ccc; }

.section .card-profile {
  margin-top: 100px; }

.landing-alert {
  margin-bottom: 0; }

.page-header {
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .page-header .filter::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .page-header.page-header-small {
    min-height: 65vh !important;
    max-height: 700px; }
  .page-header.page-header-xs {
    min-height: 40vh !important; }
  .page-header.section-dark .content-center,
  .page-header.section-dark .category-absolute {
    z-index: 1; }

.page-header .motto {
  text-align: left;
  z-index: 3;
  color: #fff;
  position: relative; }

.landing-section {
  padding: 100px 0;
  z-index: 1; }

.section-buttons {
  z-index: 1; }

.landing-section .btn-simple {
  padding: 0; }

.landing-section .column {
  padding: 0 75px 0 25px; }

.team-player .img-circle, .team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px; }

.contact-form {
  margin-top: 30px; }

.contact-form label {
  margin-top: 15px; }

.contact-form .btn {
  margin-top: 30px; }

.navbar-relative {
  position: relative;
  z-index: 2; }

#register-navbar a {
  color: #FFF; }

.register-background .container {
  margin-top: 11%;
  position: relative;
  z-index: 3; }

.register-footer {
  bottom: 20px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  color: #FFFFFF; }

.register-footer .fa-heart {
  color: #EB5E28; }

.register-card label {
  margin-top: 15px; }

.register-card .title {
  color: #B33C12;
  text-align: center; }

.register-card .btn {
  margin-top: 30px; }

.register-card .forgot {
  text-align: center; }

.profile-content {
  position: relative; }

.owner {
  text-align: center; }

.owner .avatar {
  padding: 15px;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block; }

.owner .name h4 {
  margin-top: 10px; }

.profile-tabs {
  margin: 50px 0;
  min-height: 300px; }

#following h3 {
  margin: 20px 0 40px 0; }

#follows .follows .unfollow .checkbox {
  margin-top: -15px; }

#follows .follows h6 {
  margin-top: 15px; }

.follows hr {
  margin-top: 10px; }

.title-brand {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #FFFFFF;
  display: block; }
  .title-brand .type {
    position: absolute;
    font-size: 20px;
    background: #132026;
    padding: 6px 10px;
    border-radius: 4px;
    top: 0;
    font-weight: 600;
    margin-top: 10px;
    right: -15px; }

.presentation-title {
  font-size: 8em;
  font-weight: 700;
  margin: 0;
  color: #FFFFFF;
  background: #fbf8ec;
  background: -moz-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: -webkit-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: -ms-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.presentation-subtitle {
  font-size: 1.7em;
  color: #FFFFFF; }

.category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5); }
  .category-absolute .creative-tim-logo {
    max-width: 140px;
    top: -2px;
    left: 3px;
    position: relative; }

.fog-low {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -35%;
  margin-bottom: -50px;
  width: 110%;
  opacity: .85; }
  .fog-low img {
    width: 100%; }
  .fog-low.right {
    margin-left: 30%;
    opacity: 1; }

.page-header .content-center {
  margin-top: 85px; }

.moving-clouds {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 250.625em;
  height: 43.75em;
  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite; }

@keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); } }

.following img {
  max-width: 70px; }

.profile-content .tab-content .tab-pane {
  min-height: 200px; }

.profile-content .tab-content #tweets, .profile-content .tab-content #connections, .profile-content .tab-content #media {
  height: 100%; }

.section {
  padding: 70px 0;
  position: relative;
  background-color: #FFFFFF; }

.section-with-space {
  padding: 60px 0; }

.section-gray {
  background-color: #EEEEEE; }

.section-nude {
  background-color: #FFFCF5; }

.section-gold {
  background-color: #caac90;
  color: #FFFFFF; }

.section-brown {
  background-color: #A59E94;
  color: #FFFFFF; }

.section-light-blue {
  background-color: #51cbce;
  color: #FFFFFF; }

.section-dark-blue {
  background-color: #506367;
  color: #FFFFFF; }

.section-dark {
  background-color: #0b1011; }

.section-image,
.section-gold,
.section-dark {
  position: relative; }
  .section-image .title,
  .section-image .info-title,
  .section-image .card-plain .card-title,
  .section-gold .title,
  .section-gold .info-title,
  .section-gold .card-plain .card-title,
  .section-dark .title,
  .section-dark .info-title,
  .section-dark .card-plain .card-title {
    color: #FFFFFF !important; }
  .section-image .category,
  .section-image .description,
  .section-image .card-plain .card-description,
  .section-image .card-plain .card-category,
  .section-gold .category,
  .section-gold .description,
  .section-gold .card-plain .card-description,
  .section-gold .card-plain .card-category,
  .section-dark .category,
  .section-dark .description,
  .section-dark .card-plain .card-description,
  .section-dark .card-plain .card-category {
    color: rgba(255, 255, 255, 0.7); }
  .section-image hr,
  .section-gold hr,
  .section-dark hr {
    border-color: rgba(255, 255, 255, 0.19); }

.page-header .title,
.page-header .info-title,
.carousel-caption .title,
.carousel-caption .info-title {
  color: #FFFFFF; }

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
  padding: 80px 0; }

.section-image {
  background-position: center center;
  background-size: cover; }
  .section-image:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .section-image .container {
    position: relative;
    z-index: 2; }

.section-login {
  min-height: 700px; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
  .page-header .presentation-title {
    background: none !important; } }

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
  padding: 80px 0; }

.section-image {
  background-position: center center;
  background-size: cover; }
  .section-image:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .section-image .container {
    position: relative;
    z-index: 2; }

/*          Changes for small display      */
@media (max-width: 767px) {
  #cd-vertical-nav {
    display: none !important; }
  .coloured-cards .card-big-shadow {
    margin: 0 auto; }
  .register-page .page-header {
    max-height: 2000px; }
  .section-testimonials .our-clients {
    margin-top: 0 !important; }
  .navbar-transparent {
    background-color: rgba(0, 0, 0, 0.45); }
  .register .page-header {
    max-height: fit-content; }
  .form-group.has-danger {
    margin-bottom: 30px; }
  body {
    position: relative;
    font-size: 12px; }
  h6 {
    font-size: 1em; }
  .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative; }
  .navbar .navbar-nav .dropdown-menu {
    height: 400px;
    overflow-y: scroll; }
  .demo-header .motto {
    padding-top: 30% !important; }
  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }
  .navbar-header .navbar-toggle {
    margin-top: 12px;
    width: 40px;
    height: 40px; }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent; }
  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .bar2 {
    opacity: 1; }
  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar2 {
    opacity: 0; }
  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg); }
    45% {
      top: 6px;
      transform: rotate(145deg); }
    75% {
      transform: rotate(130deg); }
    100% {
      transform: rotate(135deg); } }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg); }
    75% {
      -webkit-transform: rotate(130deg); }
    100% {
      -webkit-transform: rotate(135deg); } }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg); }
    75% {
      -moz-transform: rotate(130deg); }
    100% {
      -moz-transform: rotate(135deg); } }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg); }
    45% {
      transform: rotate(-10deg); }
    75% {
      transform: rotate(5deg); }
    100% {
      top: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg); }
    45% {
      -webkit-transform: rotate(-10deg); }
    75% {
      -webkit-transform: rotate(5deg); }
    100% {
      top: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg); }
    45% {
      -moz-transform: rotate(-10deg); }
    75% {
      -moz-transform: rotate(5deg); }
    100% {
      top: 0px;
      -moz-transform: rotate(0); } }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg); }
    45% {
      bottom: 6px;
      transform: rotate(-145deg); }
    75% {
      transform: rotate(-130deg); }
    100% {
      transform: rotate(-135deg); } }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg); }
    75% {
      -webkit-transform: rotate(-130deg); }
    100% {
      -webkit-transform: rotate(-135deg); } }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg); }
    75% {
      -moz-transform: rotate(-130deg); }
    100% {
      -moz-transform: rotate(-135deg); } }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg); }
    45% {
      transform: rotate(10deg); }
    75% {
      transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg); }
    45% {
      -webkit-transform: rotate(10deg); }
    75% {
      -webkit-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg); }
    45% {
      -moz-transform: rotate(10deg); }
    75% {
      -moz-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0); } }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .navbar-nav {
    margin: 1px -15px; }
    .navbar-nav .open .dropdown-menu > li > a {
      padding: 15px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:first-child > a {
      padding: 5px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:last-child > a {
      padding: 15px 15px 25px 50px; }
  [class*="navbar-"] .navbar-nav > li > a, [class*="navbar-"] .navbar-nav > li > a:hover, [class*="navbar-"] .navbar-nav > li > a:focus, [class*="navbar-"] .navbar-nav .active > a, [class*="navbar-"] .navbar-nav .active > a:hover, [class*="navbar-"] .navbar-nav .active > a:focus, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus, [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > li > a:active {
    color: white; }
  [class*="navbar-"] .navbar-nav > li > a:not(.btn),
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:not(.btn),
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus {
    opacity: .7;
    background: transparent; }
  [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > li > a:active {
    opacity: 1; }
  [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777; }
  [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white; }
  .footer .footer-nav,
  .footer .credits {
    margin: 0 auto; }
  #typography .typography-line .note {
    margin-left: 7px !important; }
  .dropdown-menu {
    display: none; }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden; }
  .social-line .btn {
    margin: 0 0 10px 0; }
  .subscribe-line .form-control {
    margin: 0 0 10px 0; }
  .social-line.pull-right {
    float: none; }
  .footer nav.pull-left {
    float: none !important; }
  .footer:not(.footer-big) nav > ul li {
    float: none; }
  .social-area.pull-right {
    float: none !important; }
  .form-control + .form-control-feedback {
    margin-top: 5px; }
  .form-control.form-control-danger.no-border {
    margin-top: 15px; }
  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important; }
  .btn.dropdown-toggle {
    margin-bottom: 0; }
  .media-post .author {
    width: 25%;
    float: none !important;
    display: block;
    margin: 0 auto 10px; }
  .media-post .media-body {
    width: 100%; }
  .modal-footer .btn-simple {
    padding: 15px; }
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px solid #dddddd;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }
  .typography-line {
    padding-left: 100px !important; }
  .projects-1 .project-pills .nav.nav-pills {
    display: block !important; }
    .projects-1 .project-pills .nav.nav-pills .nav-item:first-child .nav-link {
      border-radius: 12px 12px 0 0 !important;
      margin-left: -1px; }
    .projects-1 .project-pills .nav.nav-pills .nav-item.active:first-child .nav-link {
      border-radius: 12px 12px 0 0 !important;
      margin-left: -1px; }
    .projects-1 .project-pills .nav.nav-pills .nav-item:last-child .nav-link {
      border-radius: 0 0 12px 12px !important;
      margin-left: -1px; }
  .testimonials-2 .testimonials-people img,
  .section-testimonials .testimonials-people img {
    display: none !important; }
  .presentation-page .components-container .card {
    margin-bottom: 40px !important; }
  .btn-wd {
    min-width: 280px; }
  .presentation-page .title-brand .type {
    font-size: 16px;
    transform: translateY(-28px);
    -webkit-transform: translateY(-28px);
    -moz-transform: translateY(-28px);
    -o-transform: translateY(-28px); }
  .presentation-page .section-cards,
  .presentation-page .section-components {
    overflow: hidden; }
    .presentation-page .section-cards .first-card,
    .presentation-page .section-components .first-card {
      top: 750px !important; }
      .presentation-page .section-cards .first-card .grid__link,
      .presentation-page .section-components .first-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .fourth-card,
    .presentation-page .section-components .fourth-card {
      top: 940px !important; }
      .presentation-page .section-cards .fourth-card .grid__link,
      .presentation-page .section-components .fourth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .fifth-card,
    .presentation-page .section-components .fifth-card {
      top: 950px !important;
      left: 220px !important; }
      .presentation-page .section-cards .fifth-card .grid__link,
      .presentation-page .section-components .fifth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .sixth-card,
    .presentation-page .section-components .sixth-card {
      top: 1335px !important;
      left: 220px !important; }
      .presentation-page .section-cards .sixth-card .grid__link,
      .presentation-page .section-components .sixth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .seventh-card,
    .presentation-page .section-components .seventh-card {
      top: 1155px !important; }
      .presentation-page .section-cards .seventh-card .grid__link,
      .presentation-page .section-components .seventh-card .grid__link {
        max-width: 200px !important; }
  .presentation-page .section-content .image-container .add-animation {
    max-height: 250px !important;
    max-width: 180px !important; }
  .presentation-page .section-components .image-container .components-macbook {
    max-width: 580px !important;
    max-height: 400px !important; }
  .presentation-page .section-components .image-container .social-img,
  .presentation-page .section-components .image-container .share-btn-img {
    display: none; }
  .presentation-page .section-components .title {
    margin-top: -100px !important; }
  .presentation-page .section-examples {
    padding-top: 0 !important;
    margin-top: 10px !important; }
  .presentation-page .section-icons .icons-nucleo .nc-icon:not(.ninth-left-icon):not(.seventh-left-icon):not(.third-left-icon) {
    display: none !important; }
  .bootstrap-datetimepicker-widget {
    visibility: visible !important; }
  .presentation-page .fog-low {
    margin-bottom: -35px; }
  .presentation-page .presentation-title {
    font-size: 5em; }
  .presentation-page .presentation-subtitle {
    margin-top: 40px; }
  .presentation-page .title-brand {
    max-width: 450px; }
    .presentation-page .title-brand .type {
      font-size: 16px; }
  .demo-icons ul li {
    width: 20%;
    float: left; }
  .info.info-separator:after {
    display: none; }
  .pricing-5 .choose-plan {
    text-align: center; }
    .pricing-5 .choose-plan .nav.nav-pills {
      display: inline-flex; }
  .pricing-5 .description {
    text-align: center; }
  .page-carousel .carousel-inner [class*='col-md'] {
    margin-left: 0 !important; }
  .page-carousel .right.carousel-control {
    margin-right: 0 !important; }
  .page-carousel .left.carousel-control {
    margin-left: 0 !important; }
  .subscribe-line .form-control {
    border: 1px solid #DDDDDD;
    padding-left: 10px; }
  .subscribe-line .btn {
    margin: 0 auto; } }

@media screen and (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translateX(230px);
    -moz-transform: translateX(230px);
    -o-transform: translateX(230px);
    -ms-transform: translateX(230px);
    transform: translateX(230px);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .navbar-collapse ul {
      position: relative;
      z-index: 3;
      height: 95%; }
    .navbar-collapse .navbar-nav > .nav-item:last-child {
      border-bottom: 0; }
    .navbar-collapse .navbar-nav > .nav-item > .nav-link {
      margin: 0px 0px;
      color: #9A9A9A !important;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5em;
      padding: 15px 0; }
      .navbar-collapse .navbar-nav > .nav-item > .nav-link:hover, .navbar-collapse .navbar-nav > .nav-item > .nav-link:active {
        color: #403D39 !important; }
    .navbar-collapse::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #FFFCF5;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
      display: block;
      content: "";
      z-index: 1; }
    .navbar-collapse.has-image::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(17, 17, 17, 0.8);
      display: block;
      content: "";
      z-index: 1; }
  .nav-open .navbar-collapse {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); }
  .nav-open .wrapper {
    left: 0;
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .nav-open .navbar-translate {
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .wrapper .navbar-collapse {
    display: none; }
  .register-page.full-screen .page-header .container {
    margin-top: 30%; }
  .dropdown.show .dropdown-menu,
  .dropdown .dropdown-menu {
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    height: auto; }
    .dropdown.show .dropdown-menu .dropdown-item:hover,
    .dropdown.show .dropdown-menu .dropdown-item:focus,
    .dropdown .dropdown-menu .dropdown-item:hover,
    .dropdown .dropdown-menu .dropdown-item:focus {
      background-color: transparent;
      border-radius: 0; }
    .dropdown.show .dropdown-menu .dropdown-item a:hover, .dropdown.show .dropdown-menu .dropdown-item a:focus,
    .dropdown .dropdown-menu .dropdown-item a:hover,
    .dropdown .dropdown-menu .dropdown-item a:focus {
      color: #403D39; }
    .dropdown.show .dropdown-menu:before, .dropdown.show .dropdown-menu:after,
    .dropdown .dropdown-menu:before,
    .dropdown .dropdown-menu:after {
      display: none; }
  .dropdown .dropdown-menu {
    display: none; }
  .dropdown.show .dropdown-menu {
    display: block;
    position: static;
    float: none;
    background: transparent !important; }
  .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden; }
  .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white; }
  .container-cards [class*="col-md-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .container-cards .social-buttons {
    z-index: 1; }
  .full-screen.login .page-header {
    display: grid; }
  .comments.media-area .media {
    display: block; }
    .comments.media-area .media .media-body {
      margin-left: 2em; }
      .comments.media-area .media .media-body p {
        margin-top: 15px; }
  .section-navbars .navbar-collapse,
  .section-navigation .navbar-collapse,
  #navbar-dropdown .navbar-collapse {
    display: none !important; }
  .header-4 video {
    width: auto;
    height: 100vh; }
  .subscribe-line .btn.btn-block {
    width: auto; }
  .form-control + .form-control-feedback {
    padding-left: 0 !important; } }

@media screen and (min-width: 991px) {
  .burger-menu .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0px;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translateX(230px);
    -moz-transform: translateX(230px);
    -o-transform: translateX(230px);
    -ms-transform: translateX(230px);
    transform: translateX(230px);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .burger-menu .navbar-collapse .navbar-nav .nav-item:last-child {
      border-bottom: 0; }
    .burger-menu .navbar-collapse .navbar-nav {
      height: 100%;
      z-index: 2;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
    .burger-menu .navbar-collapse::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #FFFCF5;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
      display: block;
      content: "";
      z-index: 1; }
    .burger-menu .navbar-collapse.has-image::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(17, 17, 17, 0.8);
      display: block;
      content: "";
      z-index: 1; }
  .burger-menu .navbar .container .navbar-toggler {
    display: block;
    margin-top: 20px; }
  .burger-menu .navbar-translate {
    width: 100%;
    position: relative;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
  .burger-menu .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white; }
  .burger-menu .body .navbar-collapse.collapse {
    height: 100vh !important; }
  .burger-menu .navbar-collapse:before, .burger-menu .navbar-collapse:after,
  .burger-menu .navbar-nav:before,
  .burger-menu .navbar-nav:after {
    display: table;
    content: " "; }
  .nav-open .burger-menu .navbar-collapse.collapse {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); }
  .nav-open .burger-menu .wrapper {
    left: 0;
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .nav-open .burger-menu .navbar-translate {
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .burger-menu .dropdown.show .dropdown-menu,
  .burger-menu .dropdown .dropdown-menu {
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto; }
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item:hover,
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item:focus,
    .burger-menu .dropdown .dropdown-menu .dropdown-item:hover,
    .burger-menu .dropdown .dropdown-menu .dropdown-item:focus {
      background-color: transparent;
      border-radius: 0; }
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:hover, .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:focus,
    .burger-menu .dropdown .dropdown-menu .dropdown-item a:hover,
    .burger-menu .dropdown .dropdown-menu .dropdown-item a:focus {
      color: #403D39; }
    .burger-menu .dropdown.show .dropdown-menu:before, .burger-menu .dropdown.show .dropdown-menu:after,
    .burger-menu .dropdown .dropdown-menu:before,
    .burger-menu .dropdown .dropdown-menu:after {
      display: none; }
  .burger-menu .dropdown .dropdown-menu {
    display: none; }
  .burger-menu .dropdown.show .dropdown-menu {
    display: block; }
  .lightbox .modal-dialog {
    width: 960px; } }

@media screen and (min-width: 767px) {
  .section-sections .section-cols {
    position: relative;
    z-index: 1; }
  .register-page .page-header .container {
    margin-top: 150px; } }

@media (max-width: 1023px) and (min-width: 767px) {
  .presentation-page .section-content .image-container .area-img {
    max-width: 190px;
    max-height: 270px; }
  .presentation-page .section-content .image-container .info-img {
    max-width: 190px;
    max-height: 270px;
    left: -20%; }
  .presentation-page .section-content .section-description {
    margin-top: 0 !important; }
  .presentation-page .section-components .components-macbook {
    left: -75px !important; }
  .presentation-page .section-cards .codrops-header {
    margin-top: 10px !important; } }

@media (max-width: 1367px) and (min-width: 1024px) {
  .presentation-page .section-components .basic-container {
    margin-left: 5em; } }

@media (max-width: 500px) {
  .presentation-page .section-header .card {
    min-height: 230px; }
  #cards .card-profile {
    margin-top: 50px; }
  .pricing-5 .title {
    text-align: center; }
  .presentation-page .presentation-title {
    font-size: 4em; }
  .presentation-page .title-brand .type {
    font-size: 12px;
    padding: 4px 7px;
    border-radius: 4px;
    top: 0;
    font-weight: 600;
    margin-top: 20px;
    right: 2px; }
  .presentation-page .section-cards .codrops-header .description {
    max-width: 220px; }
  .register-page .page-header .container .info:first-of-type {
    margin-top: 50px; } }

@media screen and (max-width: 1367px) {
  .presentation-page .section-cards,
  .presentation-page .section-components {
    overflow: hidden; } }

@media screen and (min-width: 1025) {
  .section-sections .section-cols {
    position: relative;
    z-index: 1; }
    .section-sections .section-cols .row:first-of-type {
      margin-top: 50px;
      margin-right: -120px;
      margin-left: -15px;
      -webkit-transform: translateX(-80px);
      -moz-transform: translateX(-80px);
      -o-transform: translateX(-80px);
      -ms-transform: translateX(-80px);
      transform: translateX(-80px); }
    .section-sections .section-cols .row:nth-of-type(2) {
      margin-left: -100px;
      margin-right: -15px;
      transform: translateX(80px); }
    .section-sections .section-cols .row:nth-of-type(3) {
      margin-right: -120px;
      margin-left: 0px;
      transform: translateX(-120px); }
    .section-sections .section-cols .row:nth-of-type(4) {
      margin-right: -100px;
      margin-left: -15px;
      transform: translateX(-50px); } }

@media only screen and (min-width: 1024px) {
  .demo-icons ul li {
    width: 12.5%;
    float: left;
    padding: 32px 0; } }

@media (max-width: 1200px) {
  .presentation-page .presentation-title {
    font-size: 7em; }
  .presentation-page .title-brand {
    max-width: 650px; } }

@media (max-width: 1200px) {
  .presentation .section-images .image img {
    width: 100%; } }

@media screen and (max-width: 374px) {
  .footer.footer-big .copyright .pull-left, .footer.footer-big .copyright .pull-right {
    float: none !important; }
  .full-screen.login .card.card-register {
    margin-bottom: 20px; }
  .full-screen.login .demo-footer {
    margin-left: -15px; }
  .about-us .header-wrapper {
    height: auto !important; }
  .presentation-page .section-content .image-container .area-img {
    max-width: 170px !important; } }

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
/*

This file was modified by Creative-Tim

*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

/* Offset direction
*/
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute;
  top: -2px; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 2px; }

.noUi-horizontal .noUi-handle {
  background: #fff;
  border-radius: 50%;
  box-shadow: unset;
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-left: -10px;
  margin-top: -4px; }

.noUi-vertical {
  width: 8px; }

.noUi-vertical .noUi-handle {
  background: #fff;
  background: linear-gradient(180deg, #fff 0, #f1f1f2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white-color",endColorstr="#f1f1f2",GradientType=0);
  border-radius: 50%;
  box-shadow: inset 0 1px 1px #fff, 0 1px 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  width: 15px;
  height: 15px;
  left: -4px;
  top: -10px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -5px;
  left: auto; }

/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
  background-color: rgba(182, 182, 182, 0.3);
  border-radius: 3px; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #888;
  border-radius: 3px;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  -webkit-transition: 300ms ease 0s;
  -moz-transition: 300ms ease 0s;
  -ms-transition: 300ms ease 0s;
  -o-transform: 300ms ease 0s;
  transition: 300ms ease 0s; }
  .noUi-handle:focus {
    outline: none; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
  transform: scale3d(1.5, 1.5, 1); }

/* Disabled state;
*/
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
*
*/
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
*
*/
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
*
*/
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.slider .noUi-base {
  background-color: #c8c8c8; }

.slider.slider-neutral .noUi-connect, .slider.slider-neutral.noUi-connect {
  background-color: #FFFFFF; }

.slider.slider-neutral.noUi-target {
  background-color: rgba(255, 255, 255, 0.3); }

.slider.slider-neutral .noUi-handle {
  background-color: #FFFFFF; }

.slider.slider-primary .noUi-connect, .slider.slider-primary.noUi-connect {
  background-color: #51cbce; }

.slider.slider-info .noUi-connect, .slider.slider-info.noUi-connect {
  background-color: #51bcda; }

.slider.slider-success .noUi-connect, .slider.slider-success.noUi-connect {
  background-color: #6bd098; }

.slider.slider-warning .noUi-connect, .slider.slider-warning.noUi-connect {
  background-color: #fbc658; }

.slider.slider-danger .noUi-connect, .slider.slider-danger.noUi-connect {
  background-color: #f5593d; }

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #FFFFFF;
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent; }
  .rdtPicker:after {
    border-bottom: .4em solid #FFFFFF;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #FFFFFF !important; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #51cbce !important;
    color: #FFFFFF; }

.rdtDays tr .dow {
  text-align: center;
  border-bottom: 1px solid #E3E3E3;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #DDDDDD; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #51cbce; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #51cbce;
  border-radius: 50%;
  border: 1px solid #51cbce; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #DDDDDD;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #51cbce !important;
      color: #FFFFFF; }

.form-group .rdt {
  margin-bottom: 0;
  flex: 1 1 auto;
  width: 1%; }

.input-group .input-group-prepend + .rdt .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  padding-left: 0;
  border-right: 1px solid #DDDDDD; }

.input-group > .rdt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  padding-right: 0;
  border-left: 1px solid #DDDDDD;
  padding-left: .75rem; }

.dropdown .dropdown-menu[x-placement="top-start"] {
  -webkit-transform: translate3d(0, 0px, 0) !important;
  -moz-transform: translate3d(0, 0px, 0) !important;
  -o-transform: translate3d(0, 0px, 0) !important;
  -ms-transform: translate3d(0, 0px, 0) !important;
  transform: translate3d(0, 0px, 0) !important; }

.dropdown .dropdown-menu[x-placement="bottom-start"] {
  -webkit-transform: translate3d(0, 0px, 0) !important;
  -moz-transform: translate3d(0, 0px, 0) !important;
  -o-transform: translate3d(0, 0px, 0) !important;
  -ms-transform: translate3d(0, 0px, 0) !important;
  transform: translate3d(0, 0px, 0) !important; }

.dropdown.show .dropdown-menu[x-placement="top-start"] {
  -webkit-transform: translate3d(0, -50px, 0) !important;
  -moz-transform: translate3d(0, -50px, 0) !important;
  -o-transform: translate3d(0, -50px, 0) !important;
  -ms-transform: translate3d(0, -50px, 0) !important;
  transform: translate3d(0, -50px, 0) !important;
  top: auto !important;
  bottom: 0 !important; }

.dropdown.show .dropdown-menu[x-placement="bottom-start"] {
  -webkit-transform: translate3d(0, 40px, 0) !important;
  -moz-transform: translate3d(0, 40px, 0) !important;
  -o-transform: translate3d(0, 40px, 0) !important;
  -ms-transform: translate3d(0, 40px, 0) !important;
  transform: translate3d(0, 40px, 0) !important;
  bottom: auto !important;
  top: 0 !important; }

.input-group .input-group-append .input-group-text {
  border-right: 1px solid #DDDDDD; }

@media screen and (max-width: 991px) {
  .navbar-collapse {
    height: 100% !important; } }

@media screen and (max-width: 320px) {
  .index .presentation-subtitle {
    margin-bottom: 100px; }
  .index .category.category-absolute {
    margin-top: 200px; } }

@media screen and (max-width: 374px) {
  .index .presentation-subtitle {
    margin-bottom: 100px; }
  nav[aria-label="pagination"] {
    justify-content: center;
    text-align: center; }
    nav[aria-label="pagination"] .pagination {
      display: inline-block; }
      nav[aria-label="pagination"] .pagination .page-item {
        margin-bottom: 5px; }
  .register-page .page-header .container {
    margin-top: 7rem !important; } }

.popover {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
  z-index: 1050; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-tooltip-auto[x-placement^=top] .arrow::after, .bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^=left] .arrow::after, .bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^=right] .arrow::after, .bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^=bottom] .arrow::after, .bs-tooltip-bottom .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=left] .arrow::after, .bs-tooltip-left .arrow::after {
  right: 1px;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #fff; }

.bs-tooltip-auto[x-placement^=top] .arrow::after, .bs-tooltip-top .arrow::after {
  bottom: 1px;
  border-width: .4rem .4rem 0;
  border-top-color: #fff; }

.bs-tooltip-auto[x-placement^=right] .arrow::after, .bs-tooltip-right .arrow::after {
  left: 1px;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #fff; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::after, .bs-tooltip-bottom .arrow::after {
  top: 1px;
  border-width: 0 .4rem .4rem .4rem;
  border-bottom-color: #fff; }
