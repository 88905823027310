@import 'https://fonts.googleapis.com/css?family=Reem+Kufi';

.radio_player {
    z-index: 1;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.music-card {
    margin: 10px auto;
    background: #fff;
    box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 6px;
}

.image {
    background: url(https://images.unsplash.com/photo-1534531173927-aeb928d54385?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80) no-repeat 50%;
    background-size: cover;
    position: absolute;
    opacity: 0.8;
    height: 300px;
    width: 300px;
}

.image:after {
    height: 100px;
    content: '';
    left: 0;
    top: 200px;
    position: absolute;
    width: 100%;
    z-index: 1;
    background: linear-gradient(rgba(9, 2, 4, 0), #444);
}

.wave {
    position: absolute;
    height: 750px;
    width: 750px;
    opacity: 0.3;
    left: 0;
    top: 650px;
    margin-left: -70%;
    margin-top: -130%;
    background: radial-gradient(#353535, #383737);
}

.wave:nth-child(2),
.wave:nth-child(3) {
    top: 600px;
}

.playing .wave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
}

/* when stop */
.wave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
    transition: 0.7s;
}

.playing .wave:nth-child(2) {
    animation-duration: 4000ms;
}

/* when stop */
.wave:nth-child(2) {
    animation-duration: 50s;
}

.playing .wave:nth-child(3) {
    animation-duration: 5000ms;
}

/* when stop */
.wave:nth-child(3) {
    animation-duration: 45s;
}

.radio_info {
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    z-index: 99;
    transition: 0.7s;
}

.radio_info:hover + .wave {
    opacity: 1;
    top: 300px;

}

.radio_info:hover {
    height: 100%;
    padding-top: 50px;

}

.radio_title {
    font-size: 1.4em;
    font-weight: 400;
    color: #fff;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-family: 'Reem Kufi', sans-serif;
}

.radio_artist {
    color: #fff;
    font-size: 1.2em;
    letter-spacing: 0.08em;
    font-family: 'Reem Kufi', sans-serif;
    margin-top: -10px;
}

.radio_album {
    color: #fff;
    font-size: 1.2em;
    letter-spacing: 0.08em;
    font-family: 'Reem Kufi', sans-serif;
    margin-top: -10px;
}

.fa {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: #555;
}

#play_btn {
    cursor: pointer;
    margin: 50px auto;
    width: 150px;
}

.loader {
    color: #ffffff;
    font-size: 70px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 70px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.pause {
    color: #ffffff;
    text-indent: -9999em;
    margin: 55px auto;
    position: relative;
    font-size: 13px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.pause,
.pause:before,
.pause:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}


.pause:before,
.pause:after {
    position: absolute;
    top: 0;
    content: '';
}

.pause:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.pause:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
        margin-bottom: 8em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
        margin-bottom: 7em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
        margin-bottom: 8em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
        margin-bottom: 7em;
    }
}


.play {
    width: 100px;
    height: 100px;
    background: radial-gradient(rgba(107, 18, 18, 0.8) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 55px auto;
    box-shadow: 0px 0px 25px 3px rgba(107, 50, 50, 0.8);
}

/* triangle */
.play::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .75);
    top: -25%;
    left: -26%;
    background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;

    }
}

@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;

    }
}