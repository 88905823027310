/*==================================
    TIMELINE
==================================*/
.timeline {
    line-height: 1.4em;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.timeline h1, .timeline h2, .timeline h3, .timeline h4, .timeline h5, .timeline h6 {
    line-height: inherit;
    margin: 0;
}

/*----- TIMELINE ITEM -----*/
.timeline-item {
    padding-left: 40px;
    position: relative;
}

.timeline-item:last-child {
    padding-bottom: 0;
}

/*----- TIMELINE INFO -----*/
.timeline-info {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 0 0 .5em 0;
    text-transform: uppercase;
    white-space: nowrap;
    transition: font-size 0.3s ease-in-out;

}

/*----- TIMELINE MARKER -----*/
.timeline-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
}

.timeline-marker:before {
    background: #FF6B6B;
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.timeline-marker:after {
    content: "";
    width: 3px;
    background: #CCD5DB;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px;
}

.timeline-item:last-child .timeline-marker:after {
    content: none;
}

.timeline-item:hover .timeline-marker:before {
    background: transparent;
    border: 3px solid #FF6B6B;
}

.timeline-item:hover .timeline-info {
    font-size: larger;
}


/*----- TIMELINE CONTENT -----*/
.timeline-content {
    padding-bottom: 40px;
}

.timeline-content p:last-child {
    margin-bottom: 0;
}

/*----- TIMELINE PERIOD -----*/
.period {
    padding: 0;
}

.period .timeline-info {
    display: none;
}

.period .timeline-marker:before {
    background: transparent;
    content: "";
    width: 15px;
    height: auto;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 30px;
    position: absolute;
    border-top: 3px solid #CCD5DB;
    border-bottom: 3px solid #CCD5DB;
}

.period .timeline-marker:after {
    content: "";
    height: 32px;
    top: auto;
}

.period .timeline-content {
    padding: 40px 0 70px;
}

.period .timeline-title {
    margin: 0;
}


/*----------------------------------------------
    MOD: TIMELINE CENTERED
----------------------------------------------*/
@media (min-width: 992px) {
    .timeline-centered,
    .timeline-centered .timeline-item,
    .timeline-centered .timeline-info,
    .timeline-centered .timeline-marker,
    .timeline-centered .timeline-content {
        display: block;
        margin: 0;
        padding: 0;
    }

    .timeline-centered .timeline-item {
        padding-bottom: 40px;
        overflow: hidden;
    }

    .timeline-centered .timeline-marker {
        position: absolute;
        left: 50%;
        margin-left: -7.5px;
    }

    .timeline-centered .timeline-info,
    .timeline-centered .timeline-content {
        width: 50%;
    }

    .timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
        float: left;
        text-align: right;
        padding-right: 30px;
    }

    .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
        float: right;
        text-align: left;
        padding-left: 30px;
    }

    .timeline-centered > .timeline-item:nth-child(even) .timeline-info {
        float: right;
        text-align: left;
        padding-left: 30px;
    }

    .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
        float: left;
        text-align: right;
        padding-right: 30px;
    }

    .timeline-centered > .timeline-item.period .timeline-content {
        float: none;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    .timeline-centered .timeline-item.period {
        padding: 50px 0 90px;
    }

    .timeline-centered .period .timeline-marker:after {
        height: 30px;
        bottom: 0;
        top: auto;
    }

    .timeline-centered .period .timeline-title {
        left: auto;
    }
}
